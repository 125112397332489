<template>
	<div id='maincontent' >

		<div class="row" style="padding-bottom: 0%;">
      <div id="LineChart" class="chartBlock">
				<p>1970~2018 Terrorist Attack Casualties Line Chart.</p>
				<LineChart ref='LineChart'></LineChart>
			</div>
		
			<div id='RaceChart' class="chartBlock">
				<p>1970~2018 Terrorist Attack Organization Dynamics Ranking Chart.</p>
				<RaceChart ref='RaceChart'></RaceChart>
			</div>

			<div id="DonutChart" class="chartBlock">
				<p>Detailed information on ten high-impact terrorist organizations from 1970 to 2018.</p>
				<DonutChart ref='DonutChart'></DonutChart>
			</div>
		</div>
		<div id="Choropleth" class="chartBlock" style="padding-top: 0%;">
				<p>1970~2018 Global Terrorist Attacks/Death Tolls Map Distribution.</p>
				<Choropleth ref='Choropleth'></Choropleth>
		</div>	
	</div>
</template>

<script>
	import LineChart from './LineChart.vue'
	import RaceChart from './RaceChart.vue'
	import Choropleth from './Choropleth.vue'
	import DonutChart from './DonutChart.vue'

	export default {
		name: 'MainContent',
		components: {
			LineChart,
			RaceChart,
			Choropleth,
			DonutChart
		},
		methods: {
			// init
			renderLineChart: function() {
				this.$refs.LineChart.init()
			},
			renderRaceChart: function() {
				this.$refs.RaceChart.init()
			},
			renderChoropleth: function() {
				this.$refs.Choropleth.init()
			},
			renderDonutChart: function() {
				this.$refs.DonutChart.init()
			}
		}
	}
</script>

<style scoped>
.row {
    display: flex;
    justify-content: space-around;
}

.chartBlock {
    margin-top: 0.5rem;
    padding-top: 0.25rem;
    flex: 1;
}


#maincontent {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-start;
}

p {
	font-size: 1rem;
	font-family: "microsoft yahei";
}

#LineChart, #RaceChart, #Choropleth, #DonutChart {
    margin: 0.25rem; 
}

</style>

