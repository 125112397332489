import * as d3 from 'd3';

var rawData = []
var wordCloudData = []
var lineChartData = []
var raceChartData = []
var choroplethData = []
var donutChartData = []

function loadData(lineChartDataCallback, wordCloudDataCallback, raceChartDataCallback, choroplethDataCallback, donutChartCallback) {
	d3.csv('./lineChart.csv', d => ({
		type: +d.type,
		year: +d.year,
		attacks: +d.attacks,
		killed: +d.nkilled,
		wounded: +d.nwounded
	})).then(function(d) {
		lineChartData = JSON.parse(JSON.stringify(d));
		lineChartDataCallback();
	})

	d3.csv('./wordCloud.csv', d => ({
		cat: +d.cat,
		year: +d.year,
		type: +d.type,
		count: +d.count,
		killed: +d.killed
	})).then(function(d) {
		wordCloudData = JSON.parse(JSON.stringify(d));
		wordCloudDataCallback();
	})

	d3.csv('./gnamerank.csv', d => ({
		year: d.year,
		name: d.gnamecode,
		killed: +d.nkilled,
		attacks: +d.attacks,
	})).then(function(d) {
		raceChartData = JSON.parse(JSON.stringify(d));
		raceChartDataCallback();
	})

	d3.csv('./choropleth.csv', d => ({
		name: d.name,
		code: d.code,
		killed: +d.killed,
		attacks: +d.attacks,
	})).then(function(d) {
		choroplethData = JSON.parse(JSON.stringify(d));
		choroplethDataCallback();
	})
	
	d3.csv('./gnamedetail.csv', d => ({
		name: d.gname,
		type: +d.type,
		category: d.category,
		attacks: +d.attacks,
	})).then(function(d) {
		donutChartData = JSON.parse(JSON.stringify(d));
		donutChartCallback();
	})

}

function getWordCloudData() {
	return wordCloudData;
}

function getLineChartData() {
	return lineChartData;
}

function getRaceChartData() {
	return raceChartData;
}

function getChoroplethData() {
	return choroplethData;
}

function getDonutChartData() {
	return donutChartData;
}

var attackTypeMap = ['',
	'Assassination',
	'Armed Assault',
	'Bombing/Explosion',
	'Hijacking',
	'Hostage Taking(Barricade Incident)',
	'Hostage Taking (Kidnapping)',
	'Facility/Infrastructure Attack',
	'Unarmed Assault',
	'Unknown'
]

var targetTypeMap = ['',
	'Business',
	'Government(General)',
	'Police',
	'Military',
	'Abortion related',
	'Aitports & Aircraft',
	'Government(Diplomatic)',
	'Educational institution',
	'Food or water supply',
	'Journalists & Media',
	'Maritime',
	'NGO',
	'Other',
	'Private Citizens & Property',
	'Religious Figures/Institutions',
	'Telecommunication',
	'Terrorists/Non-State Militia',
	'Tourists',
	'Transportation',
	'Unknown',
	'Utilities',
	'Violent Political Party'
]

var weaponTypeMap = ['',
	'Biological',
	'Chemical',
	'Radiological',
	'Nuclear',
	'Firearms',
	'Explosives',
	'Fake Weapons',
	'Incendiary',
	'Melee',
	'Vehicle',
	'Sabotage Equipment',
	'Other',
	'Unknown'
]

var gnameMap = [
	"MANO-D",
	"23rd of September Communist League",
	"Unknown",
	"Black Nationalists",
	"Tupamaros (Uruguay)",
	"New Year's Gang",
	"Weather Underground, Weathermen",
	"Left-Wing Militants",
	"Armed Commandos of Liberation",
	"Commune 1",
	"Strikers",
	"Student Radicals",
	"White supremacists/nationalists",
	"Armed Forces of National Liberation- Venezuela (FALN)",
	"New People's Army (NPA)",
	"Armed Revolutionary Independence Movement (MIRA)",
	"Popular Front for the Liberation of Palestine (PFLP)",
	"Popular Front for the Liberation of Palestine, Gen Cmd (PFLP-GC)",
	"Eritrean Liberation Front",
	"Rebel Armed Forces of Guatemala (FAR)",
	"Ku Klux Klan",
	"Popular Revolutionary Vanguard (VPR)",
	"Revolutionary Force 9",
	"Puerto Rican Nationalists",
	"Chicano Liberation Front",
	"Puerto Rican Resistance Movement",
	"Black Panthers",
	"Dominican Popular Movement (MPD)",
	"Argentine Liberation Front (FAL)",
	"Argentine National Organization Movement (MANO)",
	"Japanese Red Army (JRA)",
	"Saor Eire (Irish Republican Group)",
	"New World Liberation Front (NWLF)",
	"Cuban Exiles",
	"The World United Formosans for Independence (WUFI)",
	"Palestinians",
	"Extraparliamentary Opposition (APO)",
	"Revolutionary Force 26",
	"Black Afro Militant Movement",
	"Stop the War Coalition",
	"1st of May Group",
	"Cuban Action",
	"Jewish Defense League (JDL)",
	"Right-wing extremists",
	"Montoneros (Argentina)",
	"Kabataang Makabayan (KM)",
	"Irish Republican Army (IRA)",
	"Ulster Volunteer Force (UVF)",
	"National Committee to Combat Fascism",
	"Revolutionary Force Seven",
	"Seattle Liberation Front",
	"Arabs",
	"Sandinista National Liberation Front (FSLN)",
	"American Servicemen's Union (ASU)",
	"GAC",
	"Revolutionary Armed Forces- Argentina (FAR-A)",
	"South Moluccans",
	"Croatian Nationalists",
	"SFT",
	"Jonathan Jackson Brigade",
	"Peronist Armed Forces (FAP)",
	"White Panther Party",
	"Revolutionary Action Party",
	"Turkish People's Liberation Army",
	"Ejercito Revolucionaria del Pueblo (ERP) (Argentina)",
	"Palestine Liberation Organization (PLO)",
	"Protestant extremists",
	"Siah KAL",
	"Turkish People's Liberation Front (TPLF)(THKP-C)",
	"Neo-Nazi extremists",
	"Front de Liberation du Quebec (FLQ)",
	"Quartermoon Society",
	"Students for a Democratic Society (SDS) (German)",
	"Iranians",
	"Black Liberation Army",
	"Baader-Meinhof Group",
	"Basque Fatherland and Freedom (ETA)",
	"Irish Republican Extremists",
	"Socialist Patients' Collective (SPK)",
	"Tony El Pelou Band",
	"BAY Bombers",
	"Movement of the Revolutionary Left (MIR) (Venezuela)",
	"Official Irish Republican Army (OIRA)",
	"Catalan Liberation Front (FAC)",
	"Black Cells",
	"Secret Cuban Government",
	"Students",
	"RYF",
	"Black Revolutionary Assault Team",
	"Left-wing extremists",
	"People's Revolutionary Party",
	"Ejercito de Liberacion Nacional (Bolivia)",
	"Chicano Radicals",
	"Students for a Democratic Society",
	"Regulators",
	"Revolutionary Communist League (LCR) (Spain)",
	"Black September",
	"Al-Fatah",
	"Anti-Communist extremists",
	"Iranian extremists",
	"Sons of Liberty",
	"Puerto Rican Liberation Front",
	"2nd of June Movement",
	"Jewish Armed Resistance",
	"Comrades in Arms",
	"Secret Army Organization",
	"Red Hand Commandos",
	"People's Liberation Army (United States)",
	"Jordanian National Liberation Movement",
	"Ulster Freedom Fighters (UFF)",
	"Young Cuba",
	"Turkish Leftists",
	"Comite Argentino de Lucha Anti-Imperialisto",
	"Mujahedin-e Khalq (MEK)",
	"Red Flag (Venezuela)",
	"National Front for the Liberation of Cuba (FLNC)",
	"OPR-33",
	"Puerto Rican Revolutionary Movement",
	"Popular Revolutionary Resistance Group",
	"Masada, Action and Defense Movement",
	"Iberian Liberation Movement (MIL)",
	"National Liberation Army of Colombia (ELN)",
	"National Integration Front (FIN)",
	"Resistance, Liberation and Independence Organization (AAA)",
	"Republic of New Afrika",
	"Red June of 14 June",
	"Popular Liberation Army (EPL)",
	"Black Muslims",
	"Coalition of National Brigades",
	"Armenians",
	"International Revolutionary Action Group (GARI)",
	"Black December",
	"American Indian Movement",
	"Greek Anti-Dictatorial Youth (EAN)",
	"Front for Armenian Liberation",
	"National Youth Resistance Organization",
	"Revolutionary Patriotic Anti-Fascist Front (FRAP)",
	"Revolutionary Student Front",
	"Zero Point",
	"Armed Proletarian Nuclei (NAP)",
	"Red Cell",
	"Proletarian Action Group",
	"Lebanese Socialist Revolutionary Organization",
	"Zebra killers",
	"Armenian Group",
	"Symbionese Liberation Army (SLA)",
	"Revolutionary Cells",
	"Revolutionary Peoples Army",
	"Red Brigades",
	"Muslim Guerrillas",
	"Breton Liberation Front (FLB)",
	"People's Resistance Organized Army",
	"Americans for Justice",
	"People's Liberation Army (Mexico)",
	"Corsican National Liberation Front (FLNC)",
	"Secret Organization Zero",
	"Justice Guerrillas",
	"National Liberation Front of Chad",
	"RVA",
	"People's Revolutionary Army (ERP) (El Salvador)",
	"Democratic Front for the Liberation of Palestine (DFLP)",
	"Black Order",
	"Revolutionary Arab Youth Organization",
	"Organization of the Sons of Occupied Territories",
	"Krause Group",
	"Popular Resistance Sabotage Group",
	"Jewish Extremists",
	"Fuerzas Armadas de Liberacion Nacional (FALN)",
	"Anti-Government extremists",
	"M-7",
	"Arab Communist Organization",
	"Korean Youth League",
	"Condor",
	"M12J",
	"Argentine Anticommunist Alliance (AAA)",
	"Protestant Action Group",
	"Red Flag",
	"Cuban C-4 Movement",
	"United Popular Liberation Army of America",
	"Maruseido (Marxist Youth League)",
	"Revolutionary Vanguard",
	"Latin America Anti-Communist Army (LAACA)",
	"Youth Action Group",
	"Raul Sendic International Brigade",
	"First of October Antifascist Resistance Group (GRAPO)",
	"Ananda Marga",
	"Armenian Secret Army for the Liberation of Armenia",
	"Maskini Liberation Front",
	"Omega-7",
	"Continental Revolutionary Army",
	"National Socialist Liberation Front",
	"International Revolutionary Solidarity",
	"Prisoner  Gourgen Yanikian Group",
	"National Awami Party",
	"New Order- France",
	"Movement for Cuban Justice (Pragmatistas)",
	"Irish National Liberation Army (INLA)",
	"East Asia Anti Japanese Armed Front",
	"National Organization of Cypriot Fighters (EOKA)",
	"Canary Islands Independence Movement",
	"Revolutionary Armed Forces of Colombia (FARC)",
	"Sixth of March Group",
	"Mahir Cayan Suicide Group",
	"Front for the Liberation of the French Somali Coast",
	"Red Guerilla Family",
	"Charles Martel Group",
	"Phalange",
	"People's Liberation Army (Northern Ireland)",
	"Meinhof-Puig-Antich Group",
	"The Scorpion",
	"Nationalist Intervention Group",
	"George Jackson Brigade",
	"Anti-Terrorism ETA (ATE)",
	"Movement of the Revolutionary Left (MIR) (Peru)",
	"Tribal Thumb",
	"Okinawa Liberation League",
	"Moro National Liberation Front (MNLF)",
	"Liberation Tigers of Tamil Eelam (LTTE)",
	"Fronte Paesanu Corsu di Liberazione (FPCL)",
	"South-West Africa People's Organization (SWAPO)",
	"Soldiers of the Algerian Opposition",
	"Communist Party of Malaysia- Marxist-Leninist",
	"Republican Action Force",
	"Supporters of Charles Manson",
	"Revolutionary Internationalist Solidarity",
	"Army for Freeing Scotland",
	"Military Liberation Front of Colombia",
	"United Freedom Front (UFF)",
	"Armed Nucleus for Popular Autonomy",
	"Commando 27 September",
	"Youth of the Star",
	"Justice Commandos for the Armenian Genocide",
	"Guerrilla Army of the Poor (EGP)",
	"Portuguese Liberation Army",
	"League of the 18",
	"Coup plotters",
	"M-19 (Movement of April 19)",
	"Revolutionary Command",
	"United Liberation Front for the New Algeria",
	"Revolutionary People's Struggle (ELA)",
	"Coordination of the United Revolutionary Organization (CORU)",
	"Armed Communist Struggle",
	"Cuban extremists",
	"Armed Communist Formations",
	"Portuguese Anti-Communist Movement",
	"Continuous Struggle",
	"Tigray Peoples Liberation Front (TPLF)",
	"Combat Territory Groups",
	"Fred Hampton Unit of the People's Forces",
	"Save Our Israel Land",
	"Muslim Rebels",
	"Commando Heinz Neumann",
	"Proletarian Resistance Movement",
	"Communist Combat Unit",
	"Socialist Labor Party of Lebanon",
	"Political Military Organization of Paraguay",
	"Armed Forces of National Resistance (FARN)",
	"Revolutionary Workers Party of Bolivia (PRTB)",
	"Italian Social Movement (MSI)",
	"Movement of the Revolutionary Left (MIR) (Chile)",
	"Roberto Santucho Revolutionary Group",
	"Che Guevara Brigade",
	"Cypriot",
	"Prolitarian Internationalism",
	"Anti-Castro Command",
	"Brazilian Anti-Communist Alliance (AAB)",
	"Union Guerrera Blanca (UGB)",
	"Croatian Freedom Fighters",
	"Anti-Zionist Commandos",
	"Abu Nidal Organization (ANO)",
	"Cuban Secret Army",
	"Polisario Front",
	"Rezi Rezai International Brigades",
	"Commandos Against Self Destruction of the Universe",
	"Workers Power",
	"Communist Fighting Unit",
	"African National Congress (South Africa)",
	"Independent Armed Revolutionary Commandos (CRIA)",
	"May 19 Communist Order",
	"Communist Brigade Dante Dimani",
	"Waltraud Boock Group",
	"November 17 Revolutionary Organization (N17RO)",
	"Communists",
	"Organization of Anti-Fascist Students",
	"Military Council for the True Liberation of Albania",
	"AGEL",
	"Tribal Battlefront",
	"Basque Rectitudes",
	"Armed Proletarian Power",
	"Lolita Lebron Puerto Rican Liberation Command",
	"New Partisans Movement",
	"Front for the Liberation of the Enclave of Cabinda (FLEC)",
	"Anti-Government Group",
	"Arya",
	"Revolutionary Workers Party",
	"Falangist Security Group",
	"New Order",
	"Armed Struggle for Communism",
	"Communist Brigades",
	"Armed Squads for Communism",
	"Anti-Abortion extremists",
	"African-American extremists",
	"Hanafi Muslims",
	"Combat Brigades",
	"Front For the Liberation of the Azores",
	"Somali Guerrillas",
	"Marxist-Leninist Armed Propaganda Unit",
	"Revolutionary Action",
	"Soviet and Cuban-backed rebels/mercenaries",
	"Falange",
	"Revolutionary Commandos of Solidarity",
	"Action Front for the Liberation of the Baltic Countries",
	"Committee for the Defense of Italian Workers in France",
	"People's Liberation Forces (FPL)",
	"Rote Zora",
	"Environmental Life Force",
	"Fighters for Communism",
	"Luis Boitel Commandos",
	"28 May Armenian Organization",
	"Kentavros",
	"Sanidila Secessionist Movement",
	"Prima Linea",
	"Communist Fighters Group",
	"Armed Brigades",
	"Gay Liberation Front",
	"Communist Progress",
	"Solidarist Resistance Movement",
	"Pattani United Liberation Organization (PULO)",
	"Red Guerrilla",
	"Fighting Workers for Communism",
	"Armed Communist Nucleus",
	"Combatent Communists",
	"Communist Fighting Nuclei",
	"Armed Radical Groups for Communism",
	"Armed Groups for Communism",
	"Worker Counterpower",
	"Proletarian Patrols",
	"Pedro Leon Arboleda (PLA)",
	"Armed Proletarian Groups for Communism",
	"Vyborg Brigade",
	"Avengers of Peiper Group",
	"Territorial Communist Units",
	"Proletarian Fighting Brigades",
	"Union of the People (UDP)",
	"Revolutionary Action Front",
	"Tonino Micciche Workers Nucleus",
	"Organized Proletarian Communists",
	"Nucleus of the Armed Struggle",
	"Commando Adolph Hitler",
	"Universal Liberation Front",
	"Young Brigade of Navarro",
	"Revolutionary Commandos of the People (CRP)",
	"Red Army Faction (RAF)",
	"Armed Fighting Nucleus For Communism",
	"Armed Struggle for Proletarian Power",
	"Armed Communist Commando",
	"Fighting Nucleus for Communism",
	"Communist Armed Nucleus",
	"Universal Proutist Revolutionary Federation",
	"Fighting Communist Front",
	"Breton Separatists",
	"Combat Nucleus of Communist Unity",
	"Fighting Proletarian Squads",
	"Afar rebels",
	"Free Aceh Movement (GAM)",
	"Greek National Socialist Organization",
	"Delta Group",
	"Armed Workers Squads",
	"Armed Nucleus of Communist Workers",
	"Proletarian Armed Squads",
	"Armed Proletarian Patrols",
	"Radical Communist Armed Nucleus",
	"Armed Commandos in the Fighting Army of Mario Zichieri",
	"Guerrillas of Christ the King",
	"Confederation of Iranian Students/National Union (CISNU)",
	"Armed Fight for Communism",
	"Khristos Kasimis",
	"Communist Fighting Corunim- Maria Anna Maria",
	"Worker Autonomy, Continuous Struggle",
	"Popular Revolutionary Movement (Colombia)",
	"Fighting Popular Rally",
	"PORE",
	"Christ Chaos",
	"Committee of action against bull fights",
	"28th of December Group",
	"Armed Revolutionary Groups",
	"International Communist Brigade",
	"Armed Revolutionary Nuclei (NAR)",
	"Armed Nuclei for Territorial Counterpower",
	"Southern Nucleus of Communist Counterpower",
	"Iberian Anarchist Federation",
	"Anti Shah of Iran",
	"Breton Terrorist",
	"Anti-Imperialist Fighters for a Free Palestine",
	"Catalan independence group",
	"Begumcans Cell",
	"Argentine Youths for Sovereignty",
	"Revolutionary Workers' Council (Kakurokyo)",
	"Arab Revolutionary Army",
	"Popular Revolutionary Bloc (BPR)",
	"Revolutionary Communist Party",
	"Jewish Committee of Concern",
	"Anti-Castro Group",
	"Communist Fighters Association",
	"Armed Proletarian Squads",
	"Anti-Israeli extremists",
	"Aryan Brotherhood",
	"Autonomous Students",
	"Anti-Imperialistic Communist Revolutionaries",
	"Communist Fighting Detachments",
	"Fuerza Nueva",
	"Armed Workers Nuclei",
	"Armed Womens Nuclei",
	"Armed Proletarian Group",
	"Armed Fight for Workers Power",
	"Fighting Women",
	"Armed Nuclei for Communism",
	"Commandos for the Defense of Western Civilization",
	"Proletarian Nucleus",
	"Marxist Resistance Movement",
	"Trinitarians",
	"Airport Protesters",
	"Column 88",
	"Independent Peasants Union",
	"Red Tribune",
	"Syndicalist Action Groups",
	"Front for the National Liberation of Puerto Rico",
	"Red Flying Squad",
	"Communist Anti-Imperialistic Revolutionaries",
	"Abstentionist Brigades",
	"Falangist Vanguard",
	"Unified Workers Command",
	"Chukakuha (Middle Core Faction)",
	"Left-Wing Terrorists",
	"Armed Proletarian Formations",
	"Irian Jaya Rebels",
	"Supporters of presidential candidate Belisario Bet",
	"Revolutionary Army",
	"Anti-Technology extremists",
	"Action Group for Communism",
	"Front of French National Liberation",
	"Zimbabwe African People's Union",
	"Revolutionary Anarchist Armed Terrorist Movement",
	"Death Squad",
	"AFB",
	"Anti-Imperialist Students Council",
	"Brigadas Rojas",
	"Ahmad Jibril",
	"Shining Path (SL)",
	"Macheteros",
	"Khmer Rouge",
	"Peruvian Anti-Communist Alliance (AAP)",
	"Zimbabwe Guerrillas",
	"Zimbabwe People's Army (ZIPA)",
	"Broad Commando of National Liberation",
	"Guatemalan Labor Party (PGT)",
	"Otpor",
	"Right-Wing Group",
	"Red Guard",
	"Red Resistance Front",
	"Idealist Association",
	"Secret Anti-Communist Army (ESA)",
	"United Popular Action Front (FAPU)",
	"National Union for the Total Independence of Angola (UNITA)",
	"Proletarian Combatants for Communism",
	"Muslims",
	"New Jewish Defense League",
	"Communist Attack Brigades",
	"Farabundo Marti National Liberation Front (FMLN)",
	"People's Temple",
	"Lebanese Free Will Movement",
	"International Mercenary Brigade",
	"Right Wing Christian extremists",
	"ATALA",
	"Workers' Self-Defense Movement (MAO)",
	"New Armenian Resistance",
	"Terrorists",
	"Armed Anti-Imperialist Movement",
	"Miguel Angel Cabrera Command",
	"American Anticommunist Association (AAA)",
	"Corsican Nationalists",
	"Zimbabwe Patriotic Front",
	"Combatant Nucleus for Communism",
	"National Defense Youth Corps",
	"Combatant Communist Front",
	"Basque terrorists",
	"Workers' Organization for Communism",
	"Spanish Basque Battalion (BBE) (rightist)",
	"Politico-Military Revolutionary Command",
	"Cambodian National Front for National Salvation",
	"People's Army (Myanmar)",
	"Shan State Progressive Party",
	"People's Army (Nicaragua)",
	"Organized Comrads for Communism",
	"Naga People",
	"Rhodesian guerillas",
	"Shia Muslim extremists",
	"Guards of the Fighting Proletariat",
	"Fighting Communist Unit",
	"Armed Action Force (FADA)",
	"22-Jan",
	"Armed Proletariat Fighting Squad",
	"Save Uganda Movement",
	"Autonomous Groups",
	"Fighters for the Liberation of the Turkish People",
	"ROSADO-SOTO Command",
	"Abd al-Krim Commandos",
	"Guerrillas",
	"Fedayeen Khalq (People's Commandos)",
	"Zimbabwe African Nationalist Union (ZANU)",
	"Communist Party of Thailand",
	"Corsican Separatists",
	"Communist Territorial Unit",
	"Death Squad of Organization Zero (Organizacion Cero)",
	"Spanish International Communist Party",
	"Anti-Soviet protest group",
	"Unk Iranian Guerrillas",
	"Patriotic Moroccan Front",
	"Subversives",
	"Organized Comrades for Feminist Counter-Power",
	"Karen National Union",
	"Francia",
	"Justice Party",
	"Wild Cats",
	"People's Military Organization (OMP)",
	"Mozambique National Resistance Movement (MNR)",
	"Turkish Revolutionaries",
	"Autonomous Intervention Collective Against the Zionist Presence in France",
	"Palestinian Students",
	"Dainihon Yokuko Doshikai",
	"Eagles of the Palestinian Revolution",
	"Fire Group",
	"Black March",
	"Policarpa Salauarpieta (Women's Command)",
	"Free Lebanon",
	"Jewish Combat Organization",
	"Extremists",
	"Communist Territorial Nuclei",
	"Palestine Liberation Front (PLF)",
	"Forqan Group",
	"Meitei extremists",
	"Greek Popular Resistance",
	"League of French Fighters Against Jewish Occupation",
	"Coordination for Revolutionary Action (CAR)",
	"Villagers",
	"Proletarian Fighting Patrol",
	"Lesotho Liberation Army (LLA)",
	"Left-Wing Demonstrators",
	"Communist Nuclei of Counter Power",
	"Popular Revolutionary Movement (Italy)",
	"Left-Wing Guerrillas",
	"Territorial Communist Command",
	"Hawaiian extremists",
	"Territorial Cells for Boycotting the Elections",
	"Ecology and Antinuclear Revolutionary Party (CRAE)",
	"Anti-Government Guerrillas",
	"International Committee Against Nazism",
	"Colombians",
	"Pan Africanist Congress (PAC)",
	"The Jewish Execution with Silence",
	"Mizo National Front",
	"Territorial Resistance Army",
	"Muslim Brotherhood",
	"Serbian Nationalists",
	"Radicals",
	"Republican People's Party",
	"Supporter of Bhutto",
	"Basque Justice",
	"Black Wednesday",
	"Arab Separatists",
	"Opponents of Regime",
	"Unk (men in army uniforms with arms and gas masks)",
	"Autonomy-Seeking Arabs",
	"Dev Sol",
	"Popular Movement for the Liberation of Cabinda",
	"Spanish National Action",
	"Support for Refugees (Appui aux Refugies)",
	"Arab Liberation Front (ALF)",
	"Iraqi Communists",
	"July 14 Movement",
	"Sharivad 17 Tal-Al-Za'atar(Sept 17 Tal-Al-Za'atar)",
	"Fighting Communists",
	"Organization of Avenging Palestinian Youth",
	"Personnel of the National Information Center",
	"Naxalites",
	"Tyrolean Defense League",
	"Muslim extremists",
	"Colombian Guerrillas",
	"Autonomous Group for Armed Action",
	"Revolutionary Organization of People in Arms (ORPA)",
	"Honour of the Police",
	"Central American Revolutionary Workers Party (PRTC)",
	"People's Liberation Army (India)",
	"Martyrs revenge organization",
	"Right Wing Lebanese Militiamen",
	"Italian Extreme Nationalist Organization",
	"Communist Fighting Front",
	"Kurdish Rebels",
	"Armed Forces of Popular Resistance (FARP)",
	"Pro-Palestinian extremists",
	"Separatist Arab Guerrillas",
	"Revolutionary Road",
	"Kurdish Marxist Separatists",
	"Patriotic Liberation Front (FPL)",
	"Movement of the Revolutionary Left (MIR) (Bolivia)",
	"RIF Popular Front",
	"Autonomous Anarchist Groups",
	"Urban Guerrillas",
	"East Side Action Committee",
	"Union of the Peoples of the Arabian Peninsula",
	"Mob",
	"Spanish Armed Group",
	"Ukrainian nationalists",
	"Proletarian Division for the Army of Communist Liberation",
	"Muslim Liberation Front",
	"Anti-Marxist Commando",
	"Maoists",
	"February 28 Popular League (El Salvador)",
	"Counter-revolutionaries",
	"The Great Serpent",
	"Commando 15th October",
	"Communist Hunters Command (CCC)",
	"People's Path",
	"Unione di u Populu Corsu (UPC)",
	"Union Du People Corse",
	"French Liberation Front",
	"Neo-Fascist extremists",
	"Organization for the Liberation of France from Jewish Occupation",
	"Party of National Conciliation",
	"May 15 Organization for the Liberation of Palestine",
	"Sons of the South (Shiite Muslim Group)",
	"Black American Moslems",
	"Autonomy (Italy)",
	"Action Squad",
	"Tunisian Armed Resistance",
	"Catalonian Resistance",
	"Front for the Liberation of Lebanon from Foreigners",
	"Peasant Militants",
	"Separatists",
	"Autonomous Revolutionary Brigade",
	"East Timorese extremists",
	"Revolutionary Student Brigade",
	"Left-Wing Workers",
	"Sandinistas",
	"15th of September Liberation Legion",
	"Qaddafi loyalists",
	"National Independent Committee for Political Prisoners and Persecuted and Missing Persons",
	"Right-Wing Death Squad",
	"Student Revolutionary Front (FER)",
	"Proletarian communist commandos",
	"Revolutionary Action Groups",
	"Afghans",
	"Territorial Anti-Fascist Patrols",
	"28 February Armed Group",
	"Liga Agraria Campesina",
	"Fighters of the People",
	"Anti-Fascist Territorial Group",
	"Action Directe",
	"Arab Youth Group (Militant)",
	"Mali students",
	"Iparretarrak (IK)",
	"Khmer Serei Guerrillas",
	"Quebec Separatists",
	"Iraqi Mujahideen",
	"Group Yuri Choukewitch",
	"Gunmen",
	"Communist Patrols for Counter Power",
	"Grupo Estrella",
	"Libyan Students",
	"Organizacion Democratica Nacionalista (ORDEN)",
	"Communist Attack Units",
	"Jamaica Labor Party",
	"Right Wing Vigilantes",
	"National Liberation Movement Party",
	"Territorial Armed Groups for Communism",
	"Self-Defense Against All Authority",
	"Right-Wing National Youth Front",
	"Armed Groups of Organised Proletariat",
	"Militant Zionist Resistance Movement",
	"Spanish Falange",
	"Anti-Communist Commando of the South",
	"Red June",
	"Kabul Government Agents",
	"Popular Forces of April 25",
	"Arab Liberation Army",
	"Guatemalan Communist Party",
	"Right-Wing Militants",
	"Commandos",
	"Comrades Organized in Partisan Nuclei",
	"Joint Palestinian leftist organization",
	"Working People's Alliance (WPA)",
	"Jordanian Masses Organization",
	"Autonomous Anti-Capitalist Commandos (CAA)",
	"Bolivian Socialist Falange",
	"Workers of the Company",
	"Revolutionary Coordination of the Masses",
	"Communist Anti-Nuclear Front",
	"Secessionists",
	"Al-Da'wah Party",
	"Organization of Volunteers for the Puerto Rican Revolution",
	"Egyptian Revolutionary Organization",
	"Welsh Extremists",
	"Guardsmen of Islam",
	"Ex-Somoza National Guard",
	"Popular Anti-Communist Militias",
	"Somoza Sympathizers",
	"Anti-Communist Patriotic Front",
	"Amal",
	"Iraqi extremists",
	"Revolutionary Committee for Counter Force",
	"Worldwide Organization of Native Taiwanese",
	"Somozists",
	"Organization for Purging the Majlis (Parliament)",
	"Sham 'unite",
	"Committee for the Safeguard of the Islamic Revolution",
	"Fedayeen",
	"People's Revolutionary Union",
	"April 6th Liberation Movement",
	"Free Revolutionaries Movement-The Giants",
	"Patria Nueva (New Country)",
	"Malaysian Communist Party",
	"Dev Yol",
	"Maximiliano Hernandez Martinez Brigade",
	"National Liberation Army (El Salvador)",
	"Revolutionary Popular Left",
	"Armed Group for the Liberation of Guadeloupe (GLAG)",
	"Democratic Revolutionary Front (FDR)",
	"Bloque Anti-Guerrillero de Oriente (BAGO)",
	"Forces of the Struggling Ranks",
	"Pro-Iraqi Terrorists",
	"Armenian Organization for Armed Struggle",
	"Revolutionary Nationalist Movement",
	"Abu Hassan",
	"Martyr Abu Ja'far Group",
	"Rightist supporters of Democratic Alliance",
	"Iraqi Strugglers' Movement",
	"Gaddafi loyalists",
	"Lorenzo Zelaya Revolutionary Front (LZRF)",
	"Tyrol Separatists",
	"October Third Movement",
	"Revolutionary Fighting Group 80",
	"Youths",
	"International Movement of the Prolitariat",
	"Ukrainian Liberation Front",
	"Italian Combatents for Alto Adige",
	"Extreme Right Commando Brigade",
	"Wit Kommando",
	"Hatikvah Leumi or National Hope",
	"Croatian Liberation Army",
	"Oct-80",
	"Puerto Rican Armed Resistance",
	"Alliance of Revolutionary Cuban Organizations",
	"Extortionists",
	"Afghan Rebels",
	"Guadeloupe Liberation Army",
	"Italians Attacking Policies of the Regional  SVP",
	"Prisoner's Action Force",
	"Territorial Patrols of Anti-Fascist Vigilance",
	"Unified Kurdish Socialist Party",
	"Somali Salvation Front (SSF)",
	"Guadeloupe Separatists",
	"Scottish Socialist Republican League",
	"Ninth of June Organization",
	"Political Activists",
	"Cinchoneros Popular Liberation Movement",
	"Armenian Guerrillas",
	"Anti-Immigrant extremists",
	"Syrian Terror",
	"Uganda Freedom Movement (UFM)",
	"Vincente Mecha Revolutionary Christians",
	"Mexican Students",
	"Arab Revolution/Liberation Vanguard Organization",
	"Autonomous Resistance",
	"Three Stars Autonomous Commando",
	"Revolutionary Communist Youth Brigade (RCYB)",
	"Budget for the Popular Prep or Death",
	"GP II",
	"Martyrs of La Talanguera",
	"German Speaking Separatists",
	"The Armed Secret Organization",
	"Left wing activists",
	"Martyr Ali Nasir group",
	"Popular Militia (Chile)",
	"Syrians",
	"P.R.E.",
	"Al-Zulfikar",
	"The United Southern Front",
	"Dissidents linked to Zanga",
	"Carlos Echeverria commando group",
	"French Basque Nationalists",
	"Komando Jihad (Indonesian)",
	"Anti-AF",
	"Popular Resistance Army (PRA)",
	"Insurgents",
	"Imperial Iranian Patriotic Organization",
	"31 January People's Front (FP-31)",
	"Commando Delta",
	"Extreme Right-wing Socialist Phalange",
	"Pessach Group",
	"Movement of the Third Reich",
	"Grey Wolves",
	"Patriotic Union of Kurdistan (PUK)",
	"International Proletarian Groups",
	"Terra Lliure",
	"Right-wing Terrorists",
	"Turks",
	"People's Brigade For A Healthy Genetic Future",
	"Awami League",
	"South African Liberation Support Cadre (SALSC)",
	"Revolutionary Anti-Capitalist Initiative",
	"Kurds",
	"Armed People",
	"Student Front of the People",
	"Revolutionary Communist Armed Nuclei",
	"Rebels",
	"Seaborne Moslem Raiders",
	"Kuna Indians",
	"Libyan",
	"Jewish Defenders",
	"Basque Country Autonomous Self-Defense Group",
	"People's Liberation Movement",
	"Guerrilla Commando of the people",
	"Albanian extremists",
	"Kamal Udwan Unit of Palestinians",
	"Central African National Liberation Movement",
	"Workers Brigade for Communism",
	"Kurdish guerrillas",
	"Anti-Communist Viets Organization",
	"Democratic Party",
	"Aghwar Fire War Group",
	"Basque Separatists",
	"Arab Steadfastness Front",
	"Independent Albanian Movement",
	"Azadejan (Liberation)",
	"Dissident",
	"National Salvation Forces Organization",
	"Black Brigade (United States)",
	"Thunder of Zion",
	"Gang",
	"Iranian Revolution Organization",
	"Communist Nuclear",
	"Orly Organization",
	"Marxists",
	"Croatians",
	"Sikh Extremists",
	"Egyptian Islamic Jihad (Al-Jihad)",
	"Dark Harvest",
	"Nicaraguans protesting their government",
	"Communist Group of Proletarian Internationalism",
	"Militant Welsh",
	"Welsh nationalists",
	"Nicaraguan Democratic Anti-Communist Movement",
	"Croatian Revolutionary Cell-Bruno Busic Dept",
	"Lebanese Armed Revolutionary Faction (LARF)",
	"Survivors of Golfech",
	"Extreme Left",
	"Bande des Rats (the rat pack) Ukranian Group",
	"Mercenaries",
	"Contras",
	"Revolutionary Armed Forces of Nicaragua (FARN)",
	"Iraqi Liberation Army",
	"Roque Dalton Commando",
	"Front Line Armed Nuclei (FLAN)",
	"Pacifist and Ecologist Committee",
	"Juan Rayo Guerilla Group",
	"MNR of the Left",
	"Karen Insurgents",
	"Antonia Martinez Student Commandos (AMSC)",
	"Death to Kidnappers (MAS)",
	"The Eradication of Evil",
	"Iconoclastic Nihilists",
	"4 August National Organization",
	"Congolese Armed Patriotic Group",
	"Muslim Commando (Iraqi revolutionary brothers)",
	"Village League",
	"Rabbi Rahane Group",
	"Kurdish Oppositionists",
	"Arab Socialist Union Members",
	"Al-Aqsa-Group",
	"Peykar",
	"Lebanese Arab Army",
	"Provisional Coordinating Committee for the Defense of Labor",
	"Robin Garcia Student Front",
	"World Punishment Organization",
	"Anti Somoza Gp, name unk",
	"Free Nasserite Revolutionaries",
	"Army of God",
	"Revolutionary Labor Commandos",
	"Communist Front for Counterpower",
	"Armenian Red Army",
	"Supporters of Joshua Nkomo",
	"Independent Nasserite Movement",
	"Vietnamese Organization to Exterminate Communists and Restore the Nation",
	"Movement of Islamic Action of Iraq",
	"Polish Revolutionary Home Army",
	"League of Communists",
	"Black Lebanon",
	"Iraqi Democratic Front",
	"Akali Dal Party",
	"Christian Group for the Respect for Life",
	"Hungarian exile",
	"Autonomy (Greece)",
	"Khomeini loyalists",
	"Armed Commandos of Student Self Defense",
	"Animal Rights Militia",
	"Anti-Nuclear extremists",
	"Druzes",
	"Sunni Muslim extremists",
	"United Jewish Underground",
	"Militia Members",
	"Autonomy Front",
	"Hector Rio De Brigade",
	"Jewish Terror",
	"Corsican Revolutionary Brigade",
	"Lebanese National Resistance Front",
	"Civic Guard",
	"Armed Struggle Organization",
	"Grupo Armado de Liberacion Argentina (GALA)",
	"Martyrs of Saad Sayel",
	"Posse Comitatus",
	"Mon Insurgents",
	"Scottish Nationalists",
	"People's Revolutionary Organization- Colombia (ORP)",
	"All India Sikh Students Federation (AISSF)",
	"2 April Group",
	"Nicaraguan Democratic Force (FDN)",
	"Hezbollah",
	"Misurasata Indian Organization",
	"Polish",
	"Revolutionary Solidarity",
	"Ruminahui Front",
	"Somali Salvation Democratic Front (SSDF)",
	"Democratic Revolutionary Alliance (ARDE)",
	"Red Brigades Fighting Communist Party (BR-PCC)",
	"Jordanian Revolutionary and Military Committee (MOUAB)",
	"Group for Martyred Isam as-Sartawi",
	"Caribbean Revolutionary Alliance (ARC)",
	"Basque guerrillas",
	"Northern Epirus Liberation Front (MAVI)",
	"Opposition Militants",
	"Scottish National Liberation Army",
	"Senki (Battle Flag)",
	"Revolutionary Front for National Liberation",
	"Nicaraguan  Revolutionary Armed Force",
	"Jamaat-al-Fuqra",
	"Artigas-Giachino Command",
	"Lebanese Resistance Group",
	"Covenant, Sword and the Arm of the Lord (CSA)",
	"Alfaro Vive",
	"Anarchists",
	"Angry Brigade",
	"Movement for Rebuilding Fatah",
	"Argentina Libre (Free Argentina)",
	"Iraqi Moslems",
	"United Popular Action Movement",
	"Morazanist Front for the Liberation of Honduras (FMLH)",
	"Amazonas Liberation Front",
	"Coordination of Anti-atomic Power Plant Saboteurs",
	"Lebanese and Palestinian Resistance Fighters",
	"North Korean involvement commandos",
	"Syrian Battle Front",
	"Comite de Liberation et de Detournements d'Ordinateurs (Committee for the liberation and hijacking of computers)",
	"The Order (Silent Brotherhood)",
	"Bolivian Luminous Path",
	"Black Liberation Force",
	"The Aref Boluki Dynamite group",
	"November 10 (Bolivia)",
	"Yamaguchi-Gumi Gang",
	"Catholic Reaction Force",
	"Sandinist People's Army (EPS)",
	"Anti-Terrorist Liberation Group (GAL)",
	"Plowshares",
	"National Front for the Liberation of Belgium",
	"Revolutionary Armed Forces (FAR)",
	"Guatemalan National Revolutionary Unity (URNG)",
	"Terror Against Terror",
	"Islamic Cause Organization",
	"Black Hand",
	"Counterrevolutionary Solidarity (SC)",
	"Muslim Militants",
	"Israeli Terrorist Group",
	"Ana Maria Martinly August 22 Brigade",
	"Al-Borkan Liberation Organization",
	"Martyrs of Baalbek",
	"Tripura National Volunteers (TNV)",
	"Patriotic Command San Jose",
	"Jammu and Kashmir Liberation Front",
	"Yellow Organization of Air Force Officers",
	"Defenders of the Fatherland",
	"Christian extremists",
	"National Resistance Movement (NRM)",
	"Jewish Direct Action",
	"National Anti Communist Commando",
	"Manuel Rodriguez Patriotic Front (FPMR)",
	"Idriss Miskine Group",
	"Congolese National Movement- Lumumba (MNCL)",
	"Tamils",
	"Revolutionary Organization of Socialist Muslims",
	"Tripura Nationalists",
	"Dishmish Regiment",
	"Internal Front",
	"MLA",
	"Katsuhisa Omori",
	"Individuals claimed to be policemen",
	"Nationalist Patriotic Alliance (APN)",
	"Sympathizers of Iranian People's Zeda'i",
	"Anti-Armenian Organization",
	"International Communist Group",
	"National Front for the Salvation of Libya",
	"Anti-Semitic extremists",
	"Islamic Action Organization",
	"Ricardo Franco Front (Dissident FARC)",
	"Scheutzen group",
	"Palestinian-nationalist Lebanese joint movement",
	"Al-Sa'iqa",
	"Alpha-66 (Cuban counterrevolutionary)",
	"Arab Revenge Organization",
	"Tupac Amaru Revolutionary Movement (MRTA)",
	"Chilean Anti-Communist Alliance (ACHA)",
	"Fedayeen of the Imperial Iranian Monarchy",
	"Free Fatherland",
	"Salvadoran Guerrillas",
	"Al-Sadr Brigades",
	"Gutierrez Gomez Resistance Group",
	"Rajneeshees",
	"Sons of the South",
	"Sharp Sword Organization",
	"Costa Rican Peoples' Army (EPC)",
	"Communists Fighting Imperialism and Armaments",
	"Anarchio Scene",
	"Narco-Terrorists",
	"Kurdistan Workers' Party (PKK)",
	"M-5",
	"Red Patriots Group",
	"Basque Refugee Support Group",
	"Anti-Socials",
	"Anti-Chon radical elements",
	"Koetoh Reh",
	"Militant Argentine Patriots",
	"Revolutionary Armed Forces for the Liberation of Colombia (FALCO)",
	"Militants",
	"Martyrs Halim Group",
	"Organization of Mauritanian Nationalists",
	"National Movement of Iranian Resistance",
	"Communist Combattant Cells (CCC) (Belgium)",
	"Separatist Clandestine Organization",
	"Yemenis",
	"September 17 Organization",
	"Independence Advocates",
	"Kanak Separatists",
	"Kanakas",
	"Kanak Socialist National Liberation Front",
	"Muslim Separatists",
	"November 21 Organization",
	"La Paz peasants federation",
	"Black September II",
	"National Socialist Council of Nagaland",
	"Yazbik Revolutionary Organization",
	"National Committee Against Independence (CNCI)",
	"Relatives of terrorist",
	"National Republican Alliance Party",
	"Palestinian/Lebanese nationalists",
	"Gruppe Haw Weg Den Scheiss",
	"National Front (Greece)",
	"Northern Terror Front",
	"Black Brigade",
	"Worker's Brigade",
	"Angels",
	"Carribbean Revolutionary Independence Army",
	"Emilio Recabarren Commando",
	"Miners",
	"New caledonian independence supporters",
	"Armenian Revolutionary Army",
	"Los Barriales",
	"Vengence Party",
	"Ahmad Alawi al Muqbili",
	"Valais Group Against Hydro-Rhone",
	"Pan-Turkish Organization",
	"Arab Unionist Nationalist Organization",
	"Quintin Lame",
	"Action Struggle Against the World",
	"Syrian Social Nationalist Party",
	"Proletarian Revolutionary Action Front (FRAP)",
	"Black extremists",
	"Tamil Eelam Liberation Organization (TELO)",
	"People's Liberation Front of Niger",
	"Martyrs of Tell Al-Zaltar",
	"Islamic Mujahidin",
	"Homeland and Freedom Group",
	"Khalid ibn Walid",
	"Miskito Indian Organization",
	"United Liberation Torchbearers Forces",
	"Peace Conquerors",
	"Anti-Muslim extremists",
	"Force 17",
	"United Democratic Front (UDF)",
	"Angolan Defense Force",
	"Poor People's Party",
	"The Ordinary Men",
	"Right-Wing Gunmen",
	"Javier Heraud Revolutionary Commando",
	"People's Revolutionary Command (CRP)",
	"Revolutionary Autonomous Group",
	"National Revolution",
	"Broad Front",
	"Islamic Unification Movement",
	"Revolutionary Christians of the Cedars",
	"Egypt's Revolution",
	"Jarnail Khalsa",
	"Arab Socialist Baath Party",
	"7 April Libyan Organization",
	"Wild Geese of the Cities",
	"Saffron Tigers",
	"Revolutionary Militant Left organization",
	"Crazy Brabant Killers",
	"Proletarian Initiative and Offensive for W. Europe",
	"Red Revolutionary Front",
	"Christian Palestinian group",
	"Anti-Apartheid guerrillas",
	"Simon Bolivar Guerrilla Coordinating Board (CGSB)",
	"Revolutionary Eelam Organization (EROS)",
	"Kisar Liberation Movement",
	"Committee of Solidarity with Arab and Middle East Political Prisoners (CSPPA)",
	"Moslem opponents of Gemayel",
	"Marxist Peoples Fedayeen of Iran",
	"America Battalion",
	"Red Brigades Fighting Communist Union (BR-UCC)",
	"Black Flag (Bandera Negra)",
	"Union of Galilee Christians",
	"Up the IRS, Inc",
	"New Ugandan Army",
	"The Order II (Bruder Schweigen Strike Force II)",
	"Vietnamese Refugees",
	"People's Command",
	"National Revolutionary Command (Umar al-Mukhtar)",
	"Islamic Liberation Organization",
	"Arab Revolutionary Cells",
	"Anti-American Arab Liberation Front",
	"Tribal Separatists",
	"Kisan Indian Organization",
	"Patria y Libertad (Fatherland and Liberty)",
	"Armed Communist Front",
	"Arab Commando Cells",
	"Commando Internacionalista Simon Bolivar",
	"Arab Revolutionary Front",
	"Anti-Imperialist International Brigades",
	"Bangladesh Sarbahara Party",
	"A Resistance Group",
	"Montoneros Patria Libre",
	"Sudan People's Liberation Army (SPLA)",
	"Salah al-Din Squad",
	"Al-Marabitun Revolutionary Committee",
	"Eelam People's Revolutionary Liberation Front (EPRLF)",
	"Commandos of France",
	"Feliciano Argueta Front",
	"Japan Socialist Youth League of the Revolutionary Workers' Association",
	"Black War",
	"Palestinian Revolution Forces",
	"Fatah Uprising",
	"Major Muhammad Zuhnyn Group",
	"United Nicaraguan Opposition",
	"Brunswijk Jungle Commando",
	"United Nasirite Organizaiton",
	"Revolutionary Liberation Cells",
	"Schiedam Youth Front",
	"The Illegal Fisherman of the Night",
	"Drug-Related Terrorists",
	"SYS",
	"S. O. S. France",
	"Into the Blue Commando of the Revolutionary Cells",
	"Commander Gonzalo Southern Group",
	"Armed Commandos for Liberation",
	"Sinhalese Extremists",
	"September 11 Commandos",
	"Supporters of Right and Freedom",
	"Down with Lubbers",
	"El Movimiento Revolucionario Independinista",
	"Aryan Nation",
	"Karamyit Singh",
	"Maoist Communist Center (MCC)",
	"Supreme Islamic Council",
	"Brazilian Democratic Mobilization Party (PMBD)",
	"Groups Seeking Independence",
	"Gang separatists terrorists",
	"Peruvian Rebels",
	"Liberation Commando Rudolf Hess",
	"Alexander Villalon (YADO)",
	"Angola Rebels",
	"Youths of Ali Movement",
	"Sea Shepherd Conservation Society",
	"The Call of Jesus Christ",
	"Suicide Squad",
	"Black Panther Group (Palestinian)",
	"Poverty Brigade",
	"Greek Bulgarian Armenian Front",
	"Khalistan Liberation Force",
	"Pro-Libyan",
	"Arab Struggle",
	"Lebanese Liberation Front",
	"Irish People's Liberation Organization (IPLO)",
	"Mustafa Aktas Fighting Unit",
	"Shanti Bahini - Peace Force",
	"Ethiopian People's Revolutionary Party",
	"Iranian dissident group",
	"Lebanese Terrorist Group",
	"Beirut Martyrs Forces Organization",
	"Condor Plan Criminals",
	"Nicaraguan Counter Revolutionaries (group unk)",
	"Khalistan Commando Force",
	"Animal Rights extremists",
	"Moro Islamic Liberation Front (MILF)",
	"Somali National Movement",
	"Pro-Kabul Provocateurs",
	"Free Galician People's Guerrilla Army",
	"Filipino Communists",
	"Scooter Borne terrorists",
	"Islamist extremists",
	"Census Opponents",
	"Antonio",
	"Inkatha Freedom Party (IFP)",
	"Extremist Nat'list Group",
	"Active Espirit de Corps of the Argentina Army",
	"Brother Julian",
	"Animal Liberation Front (ALF)",
	"Jihad Brigades",
	"Japanese Independence Patriotic Party",
	"Alejo Calatayu",
	"South African guerrillas",
	"Abu Musa Group",
	"Guerrilla Forces for Liberation",
	"Lebanese Secret Army",
	"Comuneros",
	"Red Army for the Liberation of Catalonia",
	"Nicaraguan Resistance",
	"Gurkha National Liberation Front (GNLF)",
	"Militant Cells",
	"Kurdish Separatists",
	"Muslim Fundamentalists",
	"Guardians of the Islamic Revolution",
	"Maximiliano Gomez Revolutionary Brigade",
	"Jacques de Molay Group",
	"Freedom Party",
	"People's Liberation Front (JVP)",
	"Arab Democratic Party",
	"Turkish Marxist Workers Party",
	"Kurdistan National Union",
	"Latin American Anti-Fascist Command",
	"Evan Mecham Eco-Terrorist International Conspiracy (EMETIC)",
	"Syrian Mujahideen",
	"Insurgency Commune",
	"Tanyus Shanin Armed Unit",
	"Autonomen",
	"Liberation Battalion",
	"Nuclear Liberation Front",
	"Ecuadorian Communist Youth",
	"Anti-Authority Group (Greece)",
	"Uganda Federal Army",
	"Pedro Albizu Campos Revolutionary Forces",
	"Death to Drug Traffickers",
	"Mormon Extremist",
	"The Extraditables",
	"9-Feb",
	"Kachin Independence Army (KIA)",
	"Kach",
	"Sudanese People's Liberation Forces",
	"Holy Spirit Movement",
	"Meibion Glyndwr",
	"OAS-MRP",
	"National Socialist Civic Workers Movement",
	"Shan Insurgents",
	"Sinhalese Insurgents",
	"Hotaru (Firefly)",
	"Organization of Arab Fedayeen Cells",
	"Kurdish Independence Group (name unk)",
	"Uganda People's Army",
	"Jorge Eliecer Gaitan Nationalist Movement",
	"Mano Blanca",
	"Mozambique Liberation Front (FRELIMO)",
	"Iranian Militants",
	"22-May-48",
	"Internationalist Cells",
	"Sudtiroler Volkspartei-south tyrol people's party",
	"Soldiers of Truth",
	"Organization Alliance of Cuban Intransigence",
	"Black Hand (Colombia)",
	"Rodrigo Franco Command",
	"United Liberation Front of Assam (ULFA)",
	"Vigilante Group",
	"People's Liberation Organization of Tamil Eelam",
	"Black Friday",
	"Zapatista National Liberation Army",
	"Hizb-I-Islami",
	"Zarate Willka Armed Forces of Liberation",
	"Sekihotai",
	"Keshet",
	"Ein Tyrol (One Tyrol)",
	"Animal Defense League",
	"Congolese Liberation Party (PLC)",
	"Beirut Martyrs Battalion",
	"Jewish Defense Organization",
	"Lebanese Man",
	"Al-Faruo Battalion",
	"White Wolves",
	"Patriotic Morazanista Front (FPM)",
	"Manuel Jose Arce Commando",
	"Anti-Capitalist Commando",
	"Babbar Khalsa International (BKI)",
	"Anya-Nya II Militia",
	"Afrikaner Resistance Movement (AWB)",
	"Bhinderanwale Tiger Force of Khalistan (BTHK)",
	"Socialist-Nationalist Front (SNF)",
	"Shaykh Subhi Al-Salih Forces",
	"Social Resistance",
	"Workers' Revolutionary Party",
	"Liberation Front of the Senegalese People",
	"Anti-Capitalist Brigades",
	"National Movement Against the Mahgreb Invasion",
	"Black Liberation Front",
	"Movement of Democratic Forces of Casamance",
	"Revolutionary Front for an Independent East Timor (FRETILIN)",
	"Tripoli Martyrs Battalion",
	"Ugandan Rebels",
	"Socialist Revolutionary Brigade",
	"Death to Bazuqueros",
	"16 January Organization for the Liberation of Tripoli",
	"1-May",
	"Popular Resistance Front (FPR)",
	"Earth First!",
	"Anti-Communist Action Alliance",
	"People's War Group (PWG)",
	"Anti-Imperialist Patriotic Union",
	"National Liberation Army (Peru)",
	"Bodo Militants",
	"Burma Communist Party",
	"Revolutionary United Front Movement",
	"United Revolutionary Front",
	"Mandela Soccer Club",
	"Forces of the Free Arab",
	"Muhammed Shuqayr Group",
	"Workers' Forces of Liberation",
	"Eritrean Peoples Liberation Front",
	"Martyr Ahmad Alishuay B Group",
	"Revolutionary Movement",
	"Sicarii",
	"Kampuchean Peoples National Liberation Army",
	"Popular Liberation Resistance Force",
	"Armed Struggle Cells",
	"Mwakenya Dissident Movement",
	"Ugandan People's Democratic Army",
	"Arabian Peninsula Freemen",
	"National Revolutionary Front",
	"Father of the Poor",
	"Shaykh Hassan Khalid Forces",
	"National League for Democracy",
	"Generation of Arab Fury",
	"Revolutionary Movement of People in Arms",
	"9 May People's Liberation Force",
	"Catalan Militia",
	"Rebel Socialists",
	"Organization of Mujahadin of Islam",
	"National Liberation Army (Ecuador)",
	"Americans for a Competent Federal Judicial System",
	"Armed People's Units",
	"Unidad Militar Bolivariana",
	"Anti-Communist Vigilante Group",
	"Surinamese Amerindians",
	"All for the Fatherland Movement",
	"Black Forest Command",
	"Fighting Proletarian Front",
	"Gracchus Babeuf",
	"Cali Narcotics Cartel",
	"Hamas (Islamic Resistance Movement)",
	"Cells for Solidarity Against Authority",
	"Arnoldo Camu Command",
	"Kurdish Democratic Party-Iran (KDP)",
	"Movement for the Supremacy of Reason",
	"Popular Movement for the Liberation of Angola",
	"National Liberation Union",
	"Kashmiri extremists",
	"The Unit of the Chemical Weapons Martyrs",
	"Martyr Riyad Taha Group",
	"Tucayana Amerindians",
	"Tacayana Indians",
	"Flying Eagles (Vigilante Group)",
	"France's Honour",
	"Pro-Pakistani extremists",
	"Communist Party of India",
	"Citizens Volunteer Force",
	"Bougainville Revolutionary Army (BRA)",
	"Allah's Tigers",
	"White Mercenaries",
	"Anarchist Solidarity",
	"Popular Militia (Colombia)",
	"June 16 Organization",
	"Anti-feminist extremists",
	"Struggle Against Misery and Exploitation of Peasants",
	"Reform of the Armed Forces Movement",
	"Survivors of Hama",
	"Continuing Struggle",
	"Democratic Revolutionary Party",
	"Omar Torrijos Commando for Latin American Dignity",
	"Nara Red Army",
	"Manuel Gonzales Patriotic Front",
	"Hizbul Mujahideen (HM)",
	"Seikijuku",
	"Islamic Movement Organization",
	"Organization for the oppresed in Egypt's Prisons",
	"Seiji Kesha Kokuyukai",
	"Resistance Movement of the Union of Revolt Workers",
	"Dev Genc",
	"Albanian Separatists",
	"Turkish Communist Party/Marxist (TKP-ML)",
	"Mikhail Kaltezas Anarchist Organization",
	"Rwenzururu Rebels",
	"Turkish Islamic Commandos",
	"Patriotic Liberation Front",
	"Pakistani People's Party (PPP)",
	"Patriotic Resistance Army (ERP)",
	"Mon Guerrillas",
	"Armenian nationalists",
	"Armenian extremists",
	"National Patriotic Front of Liberia (NPFL)",
	"Revolutionary Anti-Racist Action",
	"Revolutionary Action Organization of the Arab Resistance Front",
	"Revolutionary Worker Clandestine Union of the People Party (PROCUP)",
	"Mujahideen Kashmir",
	"Palestinian Islamic Jihad (PIJ)",
	"Aum Shinri Kyo",
	"Earth Night Action Group",
	"Environmentalists",
	"Islamic Front for the Liberation of Palestine (IFLP)",
	"Liberian Rebel Group",
	"Islamic Struggle Front",
	"Anti-Environmentalists",
	"Do-or-Die Corps for the Abolition of Anti-Public Taxation Systems",
	"14th of December Command",
	"20 December Movement (M-20)",
	"Islamic Jihad Beit-al Maqdis Group",
	"Nazi Boerestat Party",
	"Casamance Separatists",
	"Chondaehyop (Radical Student Coalition)",
	"Milicias Rodriguistas",
	"Armenian militants",
	"Muttahida Qami Movement (MQM)",
	"Revolutionary Labor Organization",
	"December 13 Independent Group",
	"Azania People's Organization (AZAPO)",
	"Muslim Janbaz Force",
	"Cleansing Hand (La Mano Que Limpia)",
	"Ultra Conservative Clerical Group",
	"Islamic Legion",
	"Popular Rage",
	"White Liberation Army",
	"Civil Cooperation Bureau",
	"Resistance Movement",
	"Pakistani trained terrorists",
	"Anti-Monarchists",
	"Israeli settlers",
	"Mozambique Rightest Rebels",
	"Black Tigers",
	"Armenian paramilitary group",
	"Orde Boerevoik",
	"Colombian Revolution",
	"Zulu Militants",
	"Revolutionary Flames",
	"Nestor Paz Zamora Commission (CNPZ)",
	"Cyprus Turkish People's Movement",
	"Eva Peron Organization",
	"Ba'adi (on my own behalf)",
	"Hubay Qah Group",
	"Communist Warrior's Union",
	"Justice and Liberty Warriors",
	"First May Movement",
	"Free Papua Movement (OPM-Organisasi Papua Merdeka)",
	"Sinhalese Militants",
	"Resistenza",
	"Bangladesh Nationalist Party (BNP)",
	"United Somali Congress",
	"Sipah-e-Sahaba/Pakistan (SSP)",
	"Organization for Zionist Retribution",
	"Corsican National Liberation Front- Historic Channel",
	"Committee for Direct Action Against War",
	"Palestinian support group",
	"Popular Liberation Army (Puerto Rico)",
	"Inkotanyi",
	"Jamaat-E-Islami (Bangladesh)",
	"Panthic Committee",
	"Avenge the Arab Nation",
	"Islamic Revolutionary Command",
	"Christian Liberation Army",
	"Tuareg extremists",
	"Tontons Macoutes",
	"Islamic Brotherhood",
	"Former Border Policeman",
	"Venceremos",
	"Elements Police Cobra Squadron",
	"Palestinian Extremists",
	"Dacoits",
	"Mong Thai Army (MTA)",
	"Turkish Communist Workers Party",
	"Katsina Muslim Society",
	"Thomas Muenzer Wild Band",
	"Communist Guerilla Nuclei",
	"Right-Wing Rebel Military Group",
	"Landless Peasants' Movement (MST)",
	"Recontras",
	"Hindu extremists",
	"Afghan Mujahideen",
	"Armed Falange",
	"Somali Patriotic Movement",
	"Al-Gama'at al-Islamiyya (IG)",
	"Tuareg Guerrillas",
	"Serbs",
	"Serbian rebels",
	"People's Information Group",
	"Miguel d'Escoto Brockman Community Movement",
	"Al-Umar Mujahideen",
	"Jorge Martinez Association of Combatants",
	"Cubans seeking escape from Cuba",
	"Allende Lives Commando",
	"Achwan-I-Mushbani",
	"Venezuela Cartel",
	"Ikhwan-ul-Muslimeen",
	"Kurdish Dissidents",
	"Kurdish Peshmerga Guerrillas",
	"Autonomous Manuel Rodriguez Patriotic Front",
	"Tupac Katari Guerrilla Army (EGTK)",
	"Popular Revolutionary Commandos",
	"Bangladesh Communist Party",
	"Guerilla Party of the Galician Poor",
	"Phillipine Moslems",
	"Serbian extremists",
	"Islamic Salvation Front (FIS)",
	"Kurdish Sympathizers",
	"People's Rebellion",
	"Foibe tribe",
	"Banya Tribe",
	"May-36",
	"Chonson Univ. students",
	"Taikosha, right wing group",
	"Bedouin tribesmen",
	"Kurdish Protestors",
	"Israel Militant",
	"Medellin Drug Cartel",
	"Black Berets",
	"Group for a Strong Revolutionary Movement",
	"All Nepal Free Nationalist Students Union",
	"Serbian guerrillas",
	"Organization for the Defense of Detainees Rights",
	"Kurdish extremists",
	"South Ossetian Extremists",
	"Azerbaijan Guerrillas",
	"Skinhead group",
	"All India Anna Dravida Munetra Kazgan Party",
	"Shonkonjuku Group",
	"Mohajir National Movement",
	"Temple Mount Faithful Movement",
	"Communist Party of India- Marxist",
	"Georgian Militants",
	"Kashmiri Hezbollah",
	"Karenni National Progressive Party",
	"Rebel Military Unit",
	"Liberation Youth Coordinating Board",
	"Guerrilla Movement of the Poor (MGP)",
	"People's Guerrilla Movement",
	"Armed Gold Miners",
	"Issa and Gurgura Liberation Front",
	"Pathan Tribal Group",
	"Action Group for the Destruction of the Police State",
	"Iranian exiles",
	"Anti-Government Demonstrators",
	"Turkish Islamic Jihad",
	"All Burma Students' Democratic Front (ABSDF)",
	"Palestinian Liberation Command",
	"Issui Kai",
	"Kyongbuk University Dental College Students",
	"Al-Adl Wal Ihsane",
	"Lashkari-e-Adam (Army of Adam)",
	"Free Fatherland Youth Guerrilla Army",
	"Arakan Rohingy Islamic Front",
	"Opposition Group",
	"Amhara tribal group",
	"Student Demonstrators",
	"Command for National Sovereignty",
	"Movement for Triqui Unification and Struggle",
	"Croatian Militia",
	"Mafia",
	"Former members Central Investigation Office",
	"Tutsi extremists",
	"Tayeb Al-Afghani's Islamist Group",
	"Anti-United States extremists",
	"Russian Militia",
	"Recompras",
	"Front for the Restoration of Unity and Democracy",
	"Movement for the Protection of Jerusalem",
	"Afghan Guerrillas",
	"Nandi Tribe",
	"Nordisk Rikspartiet (Nordic National Party)",
	"Turkish separatists (probably Kurds)",
	"Front of Resistance and National Liberation of Albanians",
	"People's Liberation Force",
	"Islamic Revenge Organization",
	"Supporters Opposition Leader Etienne Tshisekedi",
	"Najib Watan Party Faction",
	"Actiefront Nationalistisch Nederland",
	"Striking Minors Group",
	"Hezbollah Palestine",
	"Workers/Employees",
	"Militant Moslem Pilgrims",
	"Jamiat-e Islami-yi Afghanistan",
	"Left wing student group",
	"Islamic Golden Army",
	"Muslim Youth",
	"Libardo Moratoro Front",
	"Revolutionary Security Apparatus",
	"Hasmoneans (Jewish Settler Group)",
	"Federation of Students and Scholars of Cote d'Ivoire (FESCI)",
	"Zaghawa extremists",
	"Free All Political Prisoners in Holland",
	"Laiq Chanio Group of Dacoit Bandits",
	"God's Oppressed Army",
	"Popular Democratic Movement (MPD)",
	"Forum for the Restoration of Democracy-Kenya",
	"Bangladesh National Socialist Party",
	"Great Japan Patriotic Party",
	"Yatama",
	"Ivorian Popular Front",
	"Effiat ethnic roup or Jbibio Peoples",
	"Air and Azawak Liberation Front",
	"Supporters of Ex-President Gamsakhurdial",
	"Rwanda Patriotic Front (RPF)",
	"Comando Vermelho (Red Command)",
	"Kahane Chai",
	"People's Guerrilla Front",
	"Action Front Nationalist Librium",
	"Hutu extremists",
	"Political Group",
	"Azerbaijan Weapons Smugglers",
	"Yich Telga",
	"Dnestr Republic Separatists",
	"Supporters of leftist Gana-Oikya Front",
	"Turkish radicals",
	"Cambodian guerrillas",
	"National Front Security Service",
	"Yokoku Seiwa-Kai",
	"People's Revolutionary Organization",
	"Shahin (Falcon)",
	"Former Govt. Troops",
	"People's Fatherland Movement",
	"Jharkhand Tribal Forces",
	"United Liberation Movement for Democracy in Liberia (ULIMO)",
	"Militant Movement for Madagascan Socialism (MMSM)",
	"BZers",
	"Basic People's Congresses",
	"Baya Tribe",
	"Anti-Iran Government Exiles",
	"Anti-govt student groups",
	"Party for the Liberation of the Hutu People (PALIPEHUTU)",
	"Sabaot tribesmen",
	"Moslem Paramilitary Group",
	"Association for the Enhancement of the Justice of the Great Japan",
	"Russian separatists",
	"Ethnic Russian Separatists",
	"Sabaot Tribe",
	"Dnester region guerrilas",
	"Harakat-i-Inqilahi-i-Islami",
	"Central University students",
	"Militant Islamic Group, name not stated",
	"Artigas Guard Commando",
	"Red Dawn Front (Frente Amanecer Rojo)",
	"Juan Antonio Lavalleja Command",
	"Kolla Benjo Cruz Command",
	"Unemployed Persons",
	"Hamawand Tribe",
	"Pirates",
	"Anti-government rioters",
	"National Republican Movement for Democracy and Development (MRND)",
	"Jamaat-E-Islami (India/Pakistan)",
	"Sindhi nationalists",
	"Youth for Revolution",
	"Bodyguards of Muslim Warlord Governor Tupay Loong",
	"Burmese refugees",
	"Latvian Republic Volunteer Troops",
	"Lithuanian Land Guard",
	"Manuel Ascencio Padilla Group",
	"United People's Front",
	"National Democratic Front of Liberia",
	"Katap Ethic Group",
	"Hauso Ethnic Group",
	"Serbian irregulars",
	"Smugglers and Elephant Poachers",
	"Chilean Committee of Support for the Peruvian Revolution",
	"Igbo tribal group",
	"Islamic Party of Kenya (IPK)",
	"Heroes of Canto Grande",
	"Settlers at Kfar Darom",
	"Albanian exiles",
	"Basque extremists",
	"Hezb-e Wahdat-e Islami-yi Afghanistan",
	"Algerian Mujahideen for Muslims",
	"Likud Political Party",
	"Agudat Israel Party",
	"Muslim Demonstrators",
	"Aidid Militia",
	"French National",
	"Communist Trade Union Workers",
	"Islam Liberation Front",
	"Rebels backing deposed Pres. Zviad Gamsakhurdia",
	"Local Residents",
	"Bangladesh Road Transport Workers Federation",
	"Comando Independiente Revolucionario",
	"Tribal Group",
	"Black Malian Group",
	"Opposition movement of Colon Province Unemployed",
	"Bandits",
	"Kaitseliit Paramiltary Group",
	"Committee for the Elimination of Killer Collaborators",
	"Servants of Islam Organization",
	"Dnestr Rebels",
	"Jarrai",
	"Sovereign Panama Patriotic Front",
	"Turkish Hezbollah",
	"Georgian extremists",
	"Georgian guerrillas",
	"Hungarian Skin Head Group",
	"Togolese Peoples Rally",
	"Committee for a National Drive for Peace and Democracy",
	"Khmer Bandits",
	"Front for the Liberation of Cabinda / Cabinda Armed Forces (FLEC-FAC)",
	"Japan National Youth Alliance",
	"Union of Young Kurdish Revolutionaries",
	"Faqra Tribal Group",
	"Kurdish demonstrators",
	"Somali Gang",
	"Chinese Pirates",
	"Protesters",
	"War Veterans",
	"Forbid the Evil Group (Muslim Extremists)",
	"Khasi Students Union",
	"Panama Defense Force",
	"Abkhazian Separatists",
	"Boz-Ok (Grey Arrow)",
	"Somali National Alliance",
	"Revolutionary Bolivariano Movement 200",
	"Congress of Kabardian People",
	"Polish Skinheads",
	"Fuerzas Populares Jose Leonardo Chirinos",
	"All Tripura Tiger Force (ATTF)",
	"Grupo Libertad",
	"Supreme Council for Islamic Revolution in Iraq (SCIRI)",
	"Red Commandos",
	"National Liberation Front of Tripura (NLFT)",
	"October 11 Group",
	"Armed Islamic Movement",
	"Punitive Leftist Front",
	"National Democratic Front of Bodoland (NDFB)",
	"Chechen Rebels",
	"Demonstration led by French Anti-Nazi Hunters",
	"Nucleus of Fighting Communists",
	"Dioulas tribal group",
	"Jose Benito Escobar Workers Federation",
	"Moro National Liberation Front Splinter group",
	"Ingush Rebels",
	"Guajajara Tribe",
	"Bharatiya Janata Party",
	"Asylum Seekers",
	"Farighan",
	"Ohabi Islamic Sect",
	"Young Pioneers",
	"Militants of the National Action Party",
	"Bolivarian Army of Liberation",
	"Kenya African National Union (KANU)",
	"Iraqi Revolutionary Forces",
	"Gunmen loyal to Mohammad Farah Aideed",
	"Boer Republikeinse Leer",
	"New Patriotic Party (NPP)",
	"Greek Anarchists' Union",
	"February 27 Revolutionary Armed Forces",
	"Socialist Recovery Movement",
	"Mexican Revolutionary Movement",
	"Farmers",
	"Fourth Reich Skinheads",
	"Armed Forces for Liberation of East Timor (FALINTIL)",
	"Boricua Revolutionary Front",
	"People's Democratic Army",
	"Indipendenza",
	"Democratic Progressive Party",
	"Al-Ittihaad al-Islami (AIAI)",
	"Xhosa Tribal Workers",
	"Abkhazian guerrillas",
	"Al-Qaida",
	"Jihadi-inspired extremists",
	"Great Eastern Islamic Raiders Front (IBDA-C)",
	"Moslem Activists",
	"Revolutionary Front for Hatian Advancement and Progress (FRAPH)",
	"Minority Unity Forum",
	"Movement for Dignity and Soverignty",
	"Suriname Liberation Front",
	"Armed Islamic Group (GIA)",
	"Motherland Party",
	"Fatah Hawks",
	"Human Rights Defence Committee",
	"Former Muslim Guerrillas",
	"Revolutionary United Front (RUF)",
	"Taihikai (Great Sadness)",
	"Ningo Tribe",
	"Southern Sierra Peasant Organization",
	"The Islamic Movement",
	"Habr Gedir Clan",
	"Naga Students Federation",
	"Popular Liberation Front Urban Commando",
	"Association of Students and Pupils in Mali (AEEM)",
	"Zulu Miners",
	"Anti-Fascist Action",
	"Furious Rebellion",
	"Popular Front for the Liberation of the Sahara",
	"Puka Inti Maoist Communist Party",
	"Ultra Nationalist Group, name not given",
	"Francisco Villa People's Front",
	"Abu Sayyaf Group (ASG)",
	"Harkat ul Ansar",
	"Former Interior Ministry Officers",
	"Aryan Republican Army",
	"Kurdish Democratic Party-Iraq (KDP)",
	"Sandval (union) Movement",
	"Sammilito Sangskritik Jote (Alliance of Cultural Forums)",
	"Afghan War Veterans",
	"People's National Liberation Movement",
	"David's Sword",
	"Death to the Demobilized Militias",
	"Rival Parts",
	"Mesopotamian Army (MEZOR)",
	"Arsonists",
	"Corsican Farmers' Front",
	"Ansar Allah",
	"Long-time Muslim immigrants from Bangladesh",
	"Al-Jehad",
	"Serbian Gangsters",
	"Maoist Farm Laborers Struggle Committee (MXSS)",
	"Anti-Revolutionary Agents",
	"U/I Snipers",
	"New Indigenous Army",
	"Palestinian Activists",
	"United Students Forum",
	"Shop owners",
	"Ganda Koi",
	"Movement of the Islamic State (MEI)",
	"Korea Univ. Students",
	"Council for Popular Justice",
	"Albatros Commando",
	"Turks of Western Thrace",
	"Lord's Resistance Army (LRA)",
	"Colombia Without Guerrillas",
	"Bosnian Serbs",
	"Iraqi Islamic Vanguards for National Salvation (IIVNS)",
	"Khalistan Zindabad (Long Live Khalistan)",
	"Organized Crime",
	"Socialist Chhatra League",
	"National Democratic Party",
	"U/I Asian Gang",
	"Iraqi National Congress (INC)",
	"U/I Somali Militiamen",
	"Presidential Movement (MP) Militiamen",
	"U/I Private army of wral politician",
	"Bosnian Croats",
	"Pro-Khasbulaton Rebels",
	"National Liberation People's Front (FPLN)",
	"Aristide supporters",
	"Devrimici Halk Kurtulus Cephesi (DHKP/C)",
	"U/I Liberian Gunmen",
	"U/I Catholic Traditionalists",
	"Georgian Sabotage Group",
	"Togolese Dissidents",
	"Depositers angered",
	"United Action Council",
	"Taxi Drivers",
	"Al-Hadid",
	"Paupa New Guinea Troops",
	"Pro-Aristade",
	"Kuki Tribal Militants",
	"Opposition Jatiya Samajtantrik Del Party",
	"Black African Vigilantes",
	"Fundamentalists",
	"Dignity Command",
	"Indians",
	"White Guard",
	"United National Party",
	"Timorese Students",
	"Jaime Bateman Cayon Group (JBC)",
	"Union of Peaceful Citizens of Algeria",
	"Islamist Sufi Group",
	"Rival Party Members",
	"Rival Soccer Clubs",
	"Squatters",
	"Shangytown residents",
	"Congress Party Activists",
	"Malitia-Pro Govt",
	"Bihar People's  Party (Hindu militants)",
	"King Street",
	"Kim Pyongnil Supporters",
	"Coca Farmers",
	"Alex Boncayao Brigade (ABB)",
	"El Mico Rebel Gray",
	"Krikapi Indians",
	"Civilians Seeking Revenge",
	"Attaches",
	"State Council of Indian and Peasant Organization",
	"Committee of Coordination",
	"Paramilitaries",
	"Macetos (Paramilitary Group)",
	"Namchong-Nyon Students",
	"Pareci Indians",
	"Anti-Imperialist Cell (AIZ)",
	"Association of Demobilized Armed Forces",
	"Concerned citizens",
	"People's Alliance",
	"Ninjas",
	"Former Soldiers/Police",
	"Right-Wing Youths",
	"Afghan Merceneries",
	"Islamic Renewal Movement",
	"Cleansing Squad",
	"Egyptians",
	"Anti-Narita Extremists",
	"Shanty Dwellers",
	"Central American Movement of Solidarity (MOSCA)",
	"Democratic Karen Buddhist Army (DKBA)",
	"Democratic Front for Renewal (FDR)",
	"Opossition 48 hour general strikers",
	"Kenyan rebels from Uganda",
	"Right-Wing Paramilitaries",
	"Nomadic AFARS",
	"Movement for Democracy and Development (MDD)",
	"Burmese Minority Group Pirates",
	"National Democratic Alliance (NDA)",
	"Zairian Dissidents",
	"Hutus Former Soldiers",
	"Algeisk Wolves",
	"All-Party Students Union",
	"Jewish Fighting Organization (Eyal)",
	"Khun Sa Guerrillas",
	"Kalpar Tribesmen",
	"Local landlord employee",
	"Peasant Self-Defense Group (ACCU)",
	"Hiroyuki Jo",
	"Mazari Tribesmen",
	"Tulawie Clan",
	"Boer Sentries",
	"M-19 Splinter",
	"Supporters of opposing candidate Abdu Sakurtan",
	"Kuki tribesmen",
	"Vietnamese Detainees",
	"Bavarian Liberation Army",
	"Belarusian Liberation Army",
	"Tribal Clash",
	"Pacific Popular Front",
	"Sandinista Protester",
	"United Wa State Army",
	"St. Kitts Nevis Labor Party",
	"Huria Kristen Batak Protestan (HKBP)",
	"Professional Killers",
	"Rival Bangledesh Chhatra League Faction",
	"U/I Militia Group led by Raul Martinez",
	"Chinese Mafia",
	"National Accord Movement",
	"Al-Faran",
	"Purbo Banglar Communist Party",
	"Students  U of PNG",
	"Party for Democracy anti-nuclear-test protesters",
	"Armed Forces for a Federal Republic (FARF)",
	"Israeli extremists",
	"Taliban",
	"Anti-Establishment Nucleus",
	"Tanzeem Ittehad - I - Ulema Qabail (Tiuq)Tribesmen",
	"Commandos for A Popular alternative",
	"Islamic Salvation Front (Palestine)",
	"Revolutionary Voice of the People (VPR)",
	"Miscreants",
	"H-World/Heavenly Group",
	"Kurdish Islamic Unity Party",
	"Cuncolta Naziunalista",
	"Movement for Self-Determination",
	"Asbat al-Ansar",
	"Pro-Government Students",
	"Lavalas Supporters",
	"Peasant Squatters",
	"Dukhta-ran-e-Millat",
	"E. Timorese Youths",
	"Leftist youths",
	"Tamil Nadu Liberation Army",
	"Rival Activists",
	"Islamic Revival Movement",
	"Sons of the Gestapo",
	"Pro-Indonesia extremists",
	"Algerian Moslem Fundamentalists",
	"Slum Dwellers",
	"Disgruntled Petroecuador workers",
	"Supporters of Second Prime Minister Hun Sen",
	"Former Student Hostel",
	"leftist guerrillas-Bolivarian militia",
	"Dignity for Colombia",
	"Ethiopians",
	"International Justice Group (Gama'a al-Adela al-Alamiya)",
	"Strike Enforcers",
	"Supporters of President J-B Aristide",
	"Evangelical Christians",
	"Jammu and Kashmir Islamic Front",
	"Anti - Election Demonstrators",
	"Civil War Veterans",
	"U/l men",
	"Charlot Jacquelin Militant Front",
	"Pro-State Militiamen",
	"Direct Action Against Drugs (DADD)",
	"Cell for Internationalism",
	"Anti-Nuclear Society for a Silent Counter-Offensive (ANGST)",
	"Chechen Lone Wolf Group",
	"National Council of Maubere Resistance",
	"United Front for Nigeria's Liberation (UFNL)",
	"Student Militants",
	"Karnataka State Farmers Association",
	"Rival peasant band",
	"Rival Afghan Group",
	"Kosovo Liberation Army (KLA)",
	"Islamic Front for the Liberation of Bahrain",
	"Thavar Caste",
	"Rival caste",
	"Irianese Tribesmen",
	"Militant Peasants (NFI)",
	"Local Extremist",
	"Bani Hilal Tribe",
	"Phineas Priesthood",
	"National Socialist Underground",
	"Sudan Alliance Forces",
	"GN-95",
	"Gun runners",
	"Black Mambas",
	"Unidentified Infiltrators",
	"Organization 544",
	"Labor Union Leaders",
	"Islamic Movement of Martyrs",
	"Nihilists Faction",
	"West Nile Bank Front (WNBF)",
	"Ikhwan Jammu and Kashmir",
	"Kaka-Tribesmen",
	"Last Alternative Guerrilla Front",
	"Jagrata Towhidi Janata (Rising Faithfuls)",
	"Saudi Hezbollah",
	"Viviana Gallardo Command",
	"Afridi Tribe",
	"Turkish Revenge Brigade",
	"Ranbir Sena",
	"Continuity Irish Republican Army (CIRA)",
	"Popular Revolutionary Army (Mexico)",
	"Uighur Separatists",
	"Chinese Illegal Immigrant",
	"Self Defense Forces",
	"Unarmed Algerian",
	"Comando Bautista B",
	"Lebanese male",
	"Peasant protesters",
	"Shinwari Tribe",
	"Hanchongryun",
	"Lashkar-e-Jhangvi",
	"Bharat Zinc Workers",
	"Kidnapping gang",
	"Sipah-I-Mohammed",
	"Jatav Caste",
	"All Karanataka Youth Council",
	"Ujjan Tribe",
	"Beja Congress",
	"Bahujan Samaj Party",
	"The Organization for the Return of Legality",
	"Lufa Tribesmen",
	"Anti-Bhutto demonstrators",
	"National Renewal Party",
	"Vanuatu Mobile Force (VMF)",
	"Al-Toaiman Tribesmen",
	"Guaycaipuro Indians",
	"National Council for Defense of Democracy (NCDD)",
	"Jamiat ul-Mujahedin (JuM)",
	"Earth Liberation Front (ELF)",
	"Indian Tigers",
	"Uganda Federal Democratic Alliance (UFEDA)",
	"Fighting Guerrilla Formation",
	"Children of November",
	"Ticuna Indian",
	"Revolted Persons of the Polytech School",
	"Demonstrators",
	"National United Front for an Independent, Neutral, Peaceful, and Cooperative Cambodia (FUNCINPEC)",
	"National People's Movement Coordination Committee",
	"Al-Masni Tribe",
	"Revolutionary Nuclei",
	"Lashkar-e-Taiba (LeT)",
	"Bodo Liberation Tigers (BLT)",
	"Islamic Movement for Change",
	"Justice Army for Defenseless Peoples",
	"Boere Aanvals Troepe (BAT)",
	"Bosnian refugee",
	"Danish Neo-Nazi Group",
	"Civil Servants and Unionists",
	"Kamal Boulander Group",
	"Pakistan Muslim League (PML)",
	"Institutional Revolutionary Party (PRI)",
	"Cambodian People's Party (CPP)",
	"Revolutionary Proletarian Army",
	"Communist Party of Nepal- Maoist (CPN-M)",
	"Tribesmen Murad Tribe",
	"Death to Rustlers",
	"Anonima Sequestri",
	"Catholic E. Timurese",
	"Allied Democratic Forces (ADF)",
	"Maori",
	"Vietnamese",
	"Buddhist Monks",
	"Koreans",
	"Jihad Islamic League Front",
	"Columbia PM",
	"Timorese guerrillas",
	"Guatemalan hired assasins",
	"Pataxo Indians",
	"Republic of Texas",
	"Loyalist Volunteer Forces (LVF)",
	"Tehrik al-Mojahedin",
	"Communist Party of Nepal- Unified Marxist-Leninist (CPN-UML)",
	"Nepali Congress Party (NC)",
	"Guerrilla Command Force 97",
	"Elos security firm",
	"Republican Anticlerical Group",
	"Massive escape",
	"Revolutionary Military Council",
	"Raul Ernesto Cruz Leon, Salvadorian",
	"Sagrado Corazon Fanatic Group",
	"Andres Castro United Front",
	"Northern Diriangen Front (FND)",
	"Al-Yemenia al-Ulia Tribesmen",
	"We Who Built Sweden",
	"Tamil Liberation Army",
	"Rahanwein Resistance Army (RRA)",
	"White Legion (Georgia)",
	"Modakeke Ethnics",
	"Majerten clansmen",
	"Korubo Tribe",
	"Rival clan",
	"Convention of Liberal Reformers (CLR)",
	"Che Guevara Guerrillas",
	"Serb demonstrators",
	"Rizvon Sadirov Group",
	"Filipino Soldiers for the Country",
	"Saharan Revolutionary Armed Front (FARS)",
	"Jordanian Islamic Resistance",
	"Grupo de Combatientes Populares",
	"Commander Luciano Varela Antiguerrilla Group",
	"Bin Zabyan Tribesmen",
	"Shahid Khalsa Force",
	"Revolutionary Struggle",
	"Brelvi Muslims",
	"Islamic Jihad Organization (Yemen)",
	"Tribesmen, al-Itada",
	"Retired Soldier",
	"Kamajor Hunters",
	"Revolutionary Combat Brigades",
	"Party Unity",
	"Supporters of ret. Gen. Lino Oviedo",
	"Activists",
	"Youth Gang",
	"Modakeke Ethnic Activists",
	"Al-Sunni muslim sect",
	"Kisii Activists",
	"People's Militia of Dagestan",
	"People's Revolutionary Army (ERP)",
	"National Development Party (NDP)",
	"Algerian Islamic Extremists",
	"15-May",
	"Conscientious Arsonists (CA)",
	"Union of Democratic Forces",
	"Al-Ummah",
	"Zviadists",
	"Supporters of Ali Mahdi Mohammed",
	"14 K Triad",
	"May-98",
	"Real Irish Republican Army (RIRA)",
	"Anti-Christian extremists",
	"Arsonists for Social Cohesion",
	"Banyamulenge rebels",
	"Muslims Against Global Oppression (MAGO)",
	"Wolves of Islam",
	"Congolese Democratic Coalition",
	"Partisan Sharpshooters",
	"Supporters of Colonel Mahmud Khudoyberdiyev",
	"Orange Volunteers (OV)",
	"Tigers",
	"Tribesmen",
	"Fight Against Authority",
	"Mahdaviyat",
	"Matan Abdulle",
	"Vishwa Hindu Parishad (VHP)",
	"People Against Gangsterism and Drugs (PAGAD)",
	"Red Hand Defenders (RHD)",
	"Support of Ocalan-The Hawks of Thrace",
	"Sword of Islam",
	"Ansar Sarallah",
	"Salafist Group for Preaching and Fighting (GSPC)",
	"Mazdoor Kisan Sangram Samiti (MKSS)",
	"Harkatul Jihad-e-Islami",
	"The Front for the Liberation of the Cabinda Enclave - Renewed (FLEC)",
	"Comite d'Action Viticole",
	"SKIF Detachment",
	"National Democratic Alliance of Sudan",
	"Pemuda Pancasila",
	"New Revolutionary Alternative (NRA)",
	"Association of Mobil Spill Affected Communities (AMSAC)",
	"Anti-Imperialist Commando",
	"Anti-Racist Guerrilla Nuclei",
	"Red Line",
	"Loyalists",
	"Armed Forces Revolutionary Council (AFRC)",
	"Aitarak Militia",
	"Besi Merah Putih Militia",
	"Anti-Independence extremists",
	"People's Sovereignty Party (PDR)",
	"National Army for the Liberation of Uganda (NALU)",
	"Supporters of Ernest Wamba dia Wamba",
	"United Self Defense Units of Colombia (AUC)",
	"Fatherland",
	"Guadalcanal Liberation Army",
	"Ijaw extremists",
	"Human Rights Renewal Movement (Renovacion por los Derechos Humanos)",
	"Ariska Brodraskapet (Aryan Brotherhood)",
	"Hells Angels",
	"World Church of the Creator",
	"Anti-State Proletarian Nuclei",
	"Movement for Democracy and Justice in Chad (MDJT)",
	"Mahidi",
	"Supporters of Pascal Lissouba",
	"The Northern Alliance (or United Islamic Front for Salvation of Afghanistan - UIFSA)",
	"The Justice Department",
	"Dima Halao Daoga (DHD)",
	"Tupamaro Revolutionary Movement",
	"Adan Abyan Islamic Army (AAIA)",
	"Armata di Liberazione Naziunale (ALN)",
	"Ummah Liberation Army",
	"Communist Party of India- Marxist-Leninist",
	"Union of Revolutionary Communists in Turkey (TIKB)",
	"Tribal guerrillas",
	"Harakat ul-Mujahidin (HuM)",
	"Vigorous Burmese Student Warriors",
	"Friendly Company",
	"East Turkistan Liberation Organization",
	"Gazteriak",
	"Fatherland and Liberty Nationalist Front (FNPL - Frente Nacionalista Patria y Libertad FNPL)",
	"Angry Brigade (Italy)",
	"Bani Jaber tribe",
	"Youth of Islamic Awakening",
	"Anti-State Action",
	"Anti-Capitalist Action",
	"Egbesu Youths of the Bayelsa",
	"Zionist Resistance Fighters",
	"Anti-Zionist Movement",
	"Clandestini Corsi",
	"Al-Nawaz",
	"Bengali Tiger Force (BTF)",
	"Beli Orlovi (White Eagles)",
	"Colombian Patriotic Resistance",
	"Autonomous Decorators",
	"Anarchist Faction",
	"Odua Peoples' Congress (OPC)",
	"Revolutionary Leninist Brigades",
	"28s",
	"God's Army",
	"Erotic Anti-authority Cells",
	"National People's Party (Rashtriya Janata Dal - RJD)",
	"Anti-White extremists",
	"Squadrons of Terror (Katibat El Ahoual)",
	"Serb Radical Party",
	"Coalition to Save the Preserves (CSP)",
	"Last Generation",
	"Jaish-e-Mohammad (JeM)",
	"Iconoclasts",
	"Karbi National Volunteers (KNV)",
	"Revolutionary Perspective",
	"Revenge of the Trees",
	"Borana bandits",
	"Supporters of General Lino Oviedo",
	"National Front Against Tigers (NFAT)",
	"Fighters of Democratic Latvia",
	"International Solidarity",
	"Civil Defense Force (CDF)",
	"Revolutionary Proletarian Initiative Nuclei (NIPR)",
	"Al-Ma'unah",
	"Kamtapur Liberation Organization (KLO)",
	"Baby Liberation Army",
	"Fuerzas Armadas Revolucionarias del Pueblo (FARP)",
	"Jemaah Islamiya (JI)",
	"Islamic Movement of Uzbekistan (IMU)",
	"Black Star",
	"Armata Corsa",
	"Anarchist Struggle",
	"West Side Boys",
	"Skinheads",
	"National Socialist Council of Nagaland-Isak-Muivah (NSCN-IM)",
	"Isatabu Freedom Movement (IFM)",
	"Government Supporters",
	"Mungiki Sect",
	"Uighur Liberation Organization",
	"Anti-Communist Command (KAK)",
	"Akhilesh Singh Gang",
	"Umar al-Mukhtar Martyr Forces",
	"United Bengali Liberation Front (UBLF)",
	"Al-Aqsa Martyrs Brigade",
	"The Husayn Ubayyat Martyrs' Brigades",
	"Al-Zaidi Tribe - Mareb",
	"National Bolshevik Party (Partiya Natsionalnikh Bolshevikov - PNB)",
	"Palestinian Hezbollah",
	"The Islamic Revolution to Liberate Palestine",
	"Cambodian Freedom Fighters (CFF)",
	"Takfir wal-Hijra (Excommunication and Exodus)",
	"Allied Democratic Forces of Guinea (RDFG)",
	"Islamic Defenders' Front (FPI)",
	"Anarchists Attack Team",
	"Popular Resistance Committees",
	"Intifada Martyrs",
	"Rastas",
	"United People's Democratic Solidarity (UPDS)",
	"Civic United Front (CUF)",
	"National Liberation Army (NLA) (Macedonia)",
	"Supporters of Saddam Hussein",
	"Chukakuha Revolutionary Army",
	"Marhan Clan",
	"Civil Association for Peace in Colombia, Asocipaz",
	"Liberation Army for Presevo, Medvedja and Bujanovac (UCPMB)",
	"Forces for the Defense of Democracy (FDD)",
	"Chakma tribal group",
	"Tanzim",
	"Liberation War Veterans Association",
	"Sudan Liberation Movement",
	"Haika",
	"Dissident Republicans",
	"Kanglei Yawol Kanna Lup (KYKL)",
	"Dayak gang",
	"Rodolfo Walsh National Command",
	"Supporters of Muhammad Umar Habib",
	"Supporters of Muse Sudi Yalahow",
	"Special Purpose Islamic Regiment (SPIR)",
	"Croat Democratic Union",
	"Students Islamic Movement of India (SIMI)",
	"Anti-Imperialist Territorial Nuclei (NTA)",
	"Liberians United for Reconciliation and Democracy (LURD)",
	"Revolutionary Violence Units",
	"Al-Hamas Mujahideen",
	"Mayi Mayi",
	"Free Vietnam Movement",
	"Gilad Shalhevet Brigades",
	"Anarchist Squad",
	"Group of Carlo Giuliani",
	"Anarchist Liberation Brigade",
	"Nobles of Jordan",
	"Achik National Volunteer Council (ANVC)",
	"Albanian National Army (ANA)",
	"Popular Army Vanguards- Battalions of Return",
	"South Londonderry Volunteers (SLV)",
	"Musa Sudi Yalahow Militia",
	"Habi's Er'roub seriat",
	"Terena Indians",
	"Association Totalement Anti-Guerre (ATAG)",
	"Lashkar-e-Omar",
	"Mujahedin Kompak",
	"Armed Forces of the Chechen Republic of Ichkeria",
	"Laskar Jihad",
	"11-Sep",
	"Movement for the Actualization of the Sovereign State of Biafra (MASSOB)",
	"People's Democratic Party (PDP)",
	"Indigenous People's Federal Army (IPFA)",
	"Draa El Mizan Seriat",
	"Pentagon Kidnap Group",
	"National Youth Service of Zimbabwe",
	"Karamojong Warriors",
	"Revolutionary Force",
	"Avengers of the Infants",
	"United Kuki Liberation Front (UKLF) - India",
	"Islamic Front",
	"Ansar al-Islam",
	"Sympathizers of Al-Qaida Organization",
	"Grozny Jamaat",
	"Porattom",
	"Fanmi Lavalas",
	"Didier Ratsiraka's Militia",
	"Face to Face (Face-a-Face)",
	"Taliban (Pakistan)",
	"Kuki Revolutionary Army (KRA)",
	"Popular Resistance (Laiki Antistasi)",
	"Movement for Democratic Change (MDC)",
	"Barisan Revolusi Nasional (BRN)",
	"Al-Madina",
	"Congolese Rally for Democracy (RCD)",
	"Oromo Liberation Front",
	"Sardinian Autonomy Movement",
	"Mujahideen Islam Pattani",
	"New Revolutionary Popular Struggle (NELA)",
	"Rabid Brothers of Giuliani",
	"Al-Intiqami al-Pakistani",
	"Al-Mansoorian",
	"Ed'daoua Es'salafia Lilqadha",
	"All Nepal National Free Student Union-Revolutionary",
	"Army of the Republic of Ilirida",
	"Democratic Iraqi Opposition of Germany",
	"Guadalcanal Liberation Front (GLF)",
	"People's Revolutionary Militias (MRP)",
	"Front for Peace and Reconciliation (FRP)",
	"Al-Arifeen",
	"Islamic Shashantantra Andolon (ISA)",
	"Anti-Kim Jong-il extremists",
	"Tawhid and Jihad",
	"Jerusalem Groups Hebrew (Qvutzot Yerushalayim)",
	"Al-Badr",
	"Young Liberators of Pattani",
	"Harakat ul-Mujahidin Al-Almi",
	"Ramzi Nahra Martyr Organization",
	"Al-Nasirin (India)",
	"Fulani extremists",
	"CCCCC",
	"Save Kashmir Movement",
	"Praveen Dalam",
	"Resistenza Corsa",
	"Rashtriya Swayamsevak Sangh",
	"Islamic Fateh",
	"Shan United Revolutionary Army",
	"Supporters of Johnny Adair",
	"Vanguard of Red Youth (AKM)",
	"Jama'atul Mujahideen Bangladesh (JMB)",
	"White Legion (Ecuador)",
	"Zemun Clan",
	"Enraged Revolutionaries",
	"The War That Was Never Declared",
	"Kuki Liberation Army (KLA)",
	"Saif-ul-Muslimeen",
	"Pokot extremists",
	"Comando Malvinas Argentinas",
	"Al-Qaida in Saudi Arabia",
	"Proletarian Nuclei for Communism",
	"Muslim United Army (MUA)",
	"Salafia Jihadia",
	"People's Revolutionary Party of Kangleipak (PREPAK)",
	"Lendu extremists",
	"Swaziland Youth Congress (Swayoco)",
	"Popular Revolutionary Action",
	"Al-Shuda Brigade",
	"Egbema National Front",
	"Armed Vanguards of a Second Mohammed Army",
	"Revolutionary Cells-Animal Liberation Brigade",
	"Cannibal Army",
	"Fedayeen Imam Mahdi",
	"Al-Qaida in Iraq",
	"Kashmir Freedom Force",
	"Free Democratic People's Government of Laos",
	"United National Liberation Front (UNLF)",
	"Union of Congolese Patriots (UPC)",
	"Kuki National Front (KNF)",
	"Loyalist Action Force",
	"Informal Anarchist Federation",
	"Baathist extremists",
	"The Inevitables",
	"Al-Haramayn Brigades",
	"Armed Revolutionary Left (IRA)",
	"Moroccan extremists",
	"Mahdi Army",
	"Riyadus-Salikhin Reconnaissance and Sabotage Battalion of Chechen Martyrs",
	"Ansar al-Din",
	"Mujahedeen Brigades",
	"Mujahedeen Group",
	"Itsekiri",
	"Jaish al-Ta'ifa al-Mansura",
	"Resistance Cell",
	"Al-Qaida in the Arabian Peninsula (AQAP)",
	"Khalid ibn Walid Brigade",
	"Jenin Martyrs Brigades",
	"Mujahedeen Corps in Iraq",
	"Islamic Jihad Group (IJG)",
	"Islambouli Brigades of al-Qaida",
	"Islamic Army in Iraq (al-Jaish al-Islami fi al-Iraq)",
	"Kurdistan Freedom Hawks (TAK)",
	"Al Zawahiri Loyalists",
	"Anonymous Underground Movement (MCA)",
	"Abdullah Azzam Brigades",
	"French Armed Islamic Front",
	"Al-Sunna wal Jamma",
	"Ansar al-Sunna",
	"Jaish al-Muslimin (Army of the Muslims)",
	"1920 Revolution Brigades",
	"Hofstad Network",
	"Ansar al-Jihad",
	"Salafi Daawa Group",
	"Balochistan National Army",
	"Islamic Movement of Iraqi Mujahidin",
	"Etnocacerista Movement",
	"Global Intifada",
	"Baloch Liberation Army (BLA)",
	"Brigades of Iman Hassan-al-Basri",
	"Nationalist Integrationist Front (FNI)",
	"Mahsud Tribe",
	"Baloch Nationalists",
	"Colonel Karuna Faction",
	"Thai Islamic Militants",
	"Organization of Soldiers of the Levant",
	"Al-Nasireen Group",
	"Omar Bin Khattab Group",
	"Al-Shabaab al-Mu'minin",
	"Communist Party of India - Maoist (CPI-Maoist)",
	"Mujahedeen Shura Council",
	"Patriotic Resistance Front in Ituri (FRPI)",
	"National Anti-Corruption Front (FNA)",
	"Badr Brigades",
	"Tela Mohammed",
	"Dagestani Shari'ah Jamaat",
	"Mariano Moreno National Liberation Commando",
	"Arbav Martyrs of Khuzestan",
	"Al-Ahwaz Arab People's Democratic Front",
	"Hizb al-Tahrir al-Islami (HT)",
	"United Jihad Council",
	"Imam Hussein Brigade",
	"Karbi Tribe",
	"All Ethiopian Unity Party (AEUP)",
	"Mujahidin Ambon",
	"Niger Delta People's Volunteer Force (NDPVF)",
	"Islamic Courts Union (ICU)",
	"Mahaz-e-Inquilab",
	"Al-Qaida in Yemen",
	"Abida Tribe",
	"Al-Qaida Organization for Jihad in Sweden",
	"Anti-State Justice",
	"Revenge Brigade",
	"Fuerzas Autonómicas y Destructivas León Czolgoscz",
	"Revolutionary Action of Liberation",
	"Mujahedeen Army",
	"People's United Democratic Movement (PUDEMO)",
	"Coalition for Unity and Democracy (CUD)",
	"Ansar al-Tahwid wal Sunna",
	"Mutahida Majlis-e-Amal",
	"Supporters of Laurent Nkunda",
	"Kawal ng Pilipinas (Soldier of the Philippines)",
	"Al-Qaida in Lebanon",
	"Al-Qaida Network for Southwestern Khulna Division",
	"Kangleipak Communist Party (KCP)",
	"Young Officer Union of the New Generation and Reformist Armed Forces of the Philippines (YOU-RAFP)",
	"Solidarity with imprisoned members of Action Directe (AD)",
	"Jundallah (Iran)",
	"United Front for Democratic Change (FUC)",
	"Karbi Longri North Cachar Liberation Front (KLNLF)",
	"National Liberation Alliance of Sa Kaeo",
	"Houthi extremists (Ansar Allah)",
	"Movement for the Emancipation of the Niger Delta (MEND)",
	"Egyptian Tawhid and Jihad",
	"Gugama Youth Federation",
	"Banner of Islam",
	"Janjaweed",
	"Sunni Supporters",
	"Justice and Equality Movement (JEM)",
	"Islamic Tendency",
	"Janatantrik Terai Mukti Morcha (JTMM)",
	"Kata'ib al-Khoul",
	"Army of Islam",
	"Jaish-e-Mohammad (Iraq)",
	"First Capital Command (PCC)",
	"Bersatu",
	"Haqqani Network",
	"Martyr Sami al-Ghul Brigades",
	"Janatantrik Terai Mukti Morcha- Goit (JTMM-G)",
	"Jund al-Sahabah Group",
	"Oglaigh na hEireann",
	"People's Tamil Organization",
	"The Joint Revolutionary Council",
	"El-Feth katibat",
	"Jihadist Soldiers",
	"Islamic Companies",
	"Union of Forces for Democracy and Development (UFDD)",
	"National Redemption Front",
	"Islamic Swords of Justice in the Land of Ribat",
	"Rally of Democratic Forces (RAFD)",
	"Niger Delta Vigilante (NDV)",
	"Salafi Abu-Bakr al-Siddiq Army",
	"Janatantrik Terai Mukti Morcha- Jwala Singh (JTMM-J)",
	"Islamic State of Iraq (ISI)",
	"Ogaden National Liberation Front (ONLF)",
	"Al-Qaida in the Islamic Maghreb (AQIM)",
	"Bunda Dia Kongo (BDK)",
	"Afar Revolutionary Democratic Unity Front",
	"Democratic Front for the Liberation of Rwanda (FDLR)",
	"Sabaot Land Defense Force (SLDF)",
	"Jadid Al-Qaida Bangladesh (JAQB)",
	"Al-Qaida Kurdish Battalions (AQKB)",
	"Asa'ib Ahl al-Haqq",
	"Mujahideen Youth Movement (MYM)",
	"Anbar Salvation Council",
	"Karbi Longri National Liberation Front (KLNLF)",
	"Jund al-Sham for Tawhid and Jihad",
	"Anti-Apostate Movement Alliance (AGAP)",
	"Diyala Salvation Council",
	"Adivasi National Liberation Army (ANLA)",
	"Athens and Thessaloniki Arsonist Nuclei",
	"Just Punishment Brigades",
	"Kuki National Army (KNA)",
	"Young Communist League",
	"Popular Army for the Restoration of Democracy (APRD)",
	"Fatah al-Islam",
	"Madhesi People's Rights Forum (MPRF)",
	"Movement of Niger People for Justice (MNJ)",
	"Janatantrik Terai Mukti Morcha- Bisphot Singh (JTMM-B)",
	"Amr Bil Maroof Wa Nahi Anil Munkir",
	"Terai Army",
	"Al-Khobar",
	"Union of Chadian Forces (UFNT)",
	"Tehrik-i-Taliban Pakistan (TTP)",
	"United Janatantrik Terai Mukti Morcha (U-JTMM)",
	"Baloch Republican Army (BRA)",
	"Bhumi Uchched Pratirodh Committee (BUPC)",
	"Al-Shabaab",
	"Union Parishad",
	"Democratic Movement for the Liberation of the Eritrean Kunamas (DMLEK)",
	"Tehrik-e-Nafaz-e-Shariat-e-Mohammadi (TNSM)",
	"Al-Fajr",
	"Alcubar group",
	"Black Widows",
	"Supporters of Sultan Abd-al-Bagi",
	"Al-Fatihin Army (AFA)",
	"Janatantrik Terai Mukti Morcha- Prithvi Singh (JTMM-P)",
	"Mlada Bosna",
	"Kata'ib Hezbollah",
	"Conspiracy of Cells of Fire",
	"Armed Revolutionary Action (ENEDRA)",
	"United Democratic Terai Liberation Front (UDTLF)",
	"Nepal Defense Army",
	"The United Revolutionary Front of Bhutan",
	"Munadil al-Jumalyi Brigade",
	"The Jean Marc Rouillan Armed and Heartless Columns",
	"Ahrar Al-Jalil (Free People of the Galilee)",
	"Iraq's Jihadist Leagues",
	"Jaish Al-Umma (Army of the Nation)",
	"The Nation's Army",
	"Samyukta Janatantrik Terai Mukti Morcha (SJTMM)",
	"National Socialist Council of Nagaland-Khaplang (NSCN-K)",
	"Jihadi Movement of the Sunna People of Iran",
	"Shan State Army - South (SSA-S)",
	"Caucasus Emirate",
	"Forbidden Blockade (Greece)",
	"National Liberation Front (FNL) (Burundi)",
	"Anti-Democratic Struggle",
	"LW",
	"Terai Cobra",
	"Jharkhand Liberation Tigers (JLT)",
	"Hmar People's Convention-Democracy (HPC-D)",
	"Al-Mujahedin Brigades (Palestine)",
	"Madhesi Mukti Tigers (MMT)",
	"Runda Kumpulan Kecil (RKK)",
	"Eastern Turkistan Islamic Movement (ETIM)",
	"Janatantrik Terai Mukti Morcha- Rajan Mukti (JTMM-R)",
	"Future movement (Lebanon)",
	"Progressive Socialist Party (PSP)",
	"Conqueror Army",
	"Farmer's Movement of the Philippines (KMP)",
	"Gangs of Conscience",
	"Tamil Makkal Viduthalai Pulikal (TMVP)",
	"People's United Liberation Front (PULF)",
	"Naga People's Council (NPC)",
	"Akhil Terai Mukti Morcha (ATMM)",
	"Somali Islamic Front",
	"Kirat Janabadi Workers Party",
	"Hynniewtrep National Liberation Council (HNLC)",
	"National Alliance (Chad)",
	"Madhesi Virus Killers",
	"Niger Delta Freedom Fighters (NDDF)",
	"People's Liberation Front of India",
	"Popular Will (Greece)",
	"All Kamatapur Liberation Force",
	"South Ossetian Separatists",
	"14 March Coalition",
	"Juarez Cartel (Carrillo-Fuentes / Mexico)",
	"Turkestan Islamic Party",
	"Indian Mujahideen",
	"Anti-Liberal extremists",
	"Umbane People's Liberation Army (Swaziland)",
	"Janatantrik Terai Mukti Morcha- Ranbir Singh (JTMM-RS)",
	"National Liberation Army (Nepal)",
	"Hizbul al Islam (Somalia)",
	"Forest Brothers",
	"Jundallah (Pakistan)",
	"Gulf Cartel",
	"Army of State Liberators",
	"Forces for the Unification of the Central African Republic (FIRCA)",
	"Kurdistan Free Life Party",
	"National Congress for the Defense of the People (CNDP)",
	"Mahaz Fedai Tahrik Islami Afghanistan",
	"Incendiary Committee of Solidarity for Detainees",
	"Deccan Mujahideen",
	"Rastriya Janashakti Party (RJP)",
	"Militant Organization of Russian Nationalists",
	"Afghan Revolutionary Front",
	"Ansar Wa Mohajir (Pakistan)",
	"Ahlu-sunah Wal-jamea (Somalia)",
	"23 May Democratic Alliance (Algeria)",
	"Congolese Patriotic Resistence-Patriotic Armed Forces (Pareco/FAP)",
	"Madhesh Rastra Janatantrik Revolutionary (RJR) - Nepal",
	"Baloch Liberation Front (BLF)",
	"Mahan Madhesh Janakantri Party (MMJP)- Nepal",
	"Hushaysh Tribal Members",
	"Mongolian Mukti Morcha",
	"Supreme Command for Jihad and Liberation",
	"Bhisan Himali Bag",
	"Himali Tigers",
	"Sect of Revolutionaries (Greece)",
	"Macedonian nationalists",
	"National United Front of Democracy Against Dictatorship (UDD)",
	"Tritiya Prastuti Committee (TPC)",
	"Revolutionary Liberation Action (Epanastatiki Apelevtherotiki Drasi) - Greece",
	"Tibetan separatists",
	"Popular Front for Justice in the Congo",
	"Tharuhat Joint Struggle Committee (TJSC)",
	"Revolutionary Headquarters (Turkey)",
	"Citizen's Rights Protection Volunteers",
	"Terai Rastriya Mukti Sena (TRMS)",
	"Tehrik-e-Galba Islam",
	"Islamic Jihad Union (Uzbekistan)",
	"Minutemen American Defense",
	"Alexandros Grigoropoulos Anarchist Attack Group",
	"Balochistan Liberation United Front (BLUF)",
	"Kara clan",
	"United People's Democratic Front (UPDF) - Bangladesh",
	"Bodo People's Front (BPF)",
	"People's Committee against Police Atrocities (PCPA)",
	"Gono Bahini (GB)",
	"Lashkar-e-Islam (Pakistan)",
	"Attack Teams for the Dissolution of the Nation (Greece)",
	"Iraqi Sunni Extremists",
	"Deniers of Holidays",
	"NVF",
	"Boko Haram",
	"Zero Tolerance",
	"Illuminating Paths of Solidarity",
	"Militant Forces Against Huntingdon",
	"Revolutionary Cells (Argentina)",
	"Incel extremists",
	"Black Eagles",
	"Urhobo Revolutionary Army",
	"Lashkar-e-Balochistan",
	"The Black Sun",
	"Kokang",
	"Parbatya Chattagram Jana Sanghati Samity (PCJSS) - Bangladesh",
	"Ansar Al Sunnah (Palestine)",
	"Gholam Yahya Akbar",
	"Bangsamoro National Liberation Army",
	"Arauco Malleco Coordinating Group (CAM) - Chile",
	"Council for the Destruction of Order",
	"Islamic Party (Somalia)",
	"Freedom Eagles of Africa",
	"Al-Jub Tribe",
	"Convention of Patriots for Justice and Peace",
	"Hoodie Wearers",
	"Anarchist Action (CA / United States)",
	"Black and Red Anarchist and Anti-Authoritarians Initiative (Greece)",
	"Merille Militia",
	"Terai Janatantrik Party",
	"Jacinto Araujo Internationalist Rebel Insurrectionist Brigade",
	"Combat 18",
	"United Liberation Front of Barak Valley (ULFBV) - India",
	"Shield of Islam Brigade",
	"Red Sea Afar Democratic Organization (RSADO)",
	"Revolutionary Continuity",
	"Ansarul Islam (Pakistan)",
	"Southern Yemen Separatists",
	"United Front for Democracy Against Dictatorship",
	"Terai Janatantrik Madhes Party",
	"Africa Marine Commando",
	"Sisters in Arms",
	"Janatantrik Terai Madhesh Mukti Morcha (JTMMM)",
	"Rebellious Group Lambros Foundas",
	"Enyele Militia",
	"People's Democratic Struggle Movement",
	"Samyukta Jatiya Mukti Morcha (SJMM)",
	"Aqmur",
	"Southern Mobility Movement (Yemen)",
	"Fighting For Freedom Coalition (FFFC-Ottawa)",
	"Dynamic Youth Forum",
	"Baloch Militant Defense Army",
	"Swatantra Nepal Dal",
	"Paraguayan People's Army (EPP)",
	"Aniban ng Ayaw sa Komunista (ANAK) ",
	"Wahhabi Movement",
	"Groups for Dissemination of Revolutionary Theory and Action",
	"Popular Front of India",
	"Tehrik-e-Tuhafaz (Pakistan)",
	"Real Ulster Freedom Fighters (UFF) - Northern Ireland",
	"Karabulak Gang",
	"Mouhajiroune Brigade",
	"Al-Bakazim",
	"Friends of Freedom",
	"Vandalicia Teodoro Suarez",
	"United Democratic Liberation Army (UDLA)",
	"Terai Madheshi Mukti Morcha (TMMM)",
	"Jund Al-Tawid",
	"Volunteers of Innocent People of Nagas (VIPN)",
	"Garo National Liberation Army",
	"Sindhu Desh Liberation Army (SDLA)",
	"Niger Delta Liberation Force (NDLF)-Nigeria",
	"Anarkista Jorge Banos Front of the Everyone For The Homeland Movement (MTP)",
	"Delta Democratic Militia",
	"Misseriya Arab Tribesmen",
	"Karbi People's Liberation Tigers (KPLT)",
	"Black Hawks (Anti-Wahhabists)",
	"Baloch Mussalah Diffah Tanzim (BMDT)",
	"Madhesh Mukti Sangram (MMS)",
	"Tawhid and Jihad (Palestine)",
	"Jamaah Ansharut Tauhid (JAT)",
	"Arab People's Group",
	"South Sudan Liberation Army (SSLA)",
	"Khumbuwan Mukti Morcha",
	"Sanghiya Limbuwan Committee (SLC)",
	"Ansaru (Jama'atu Ansarul Muslimina Fi Biladis Sudan)",
	"Naga National Council (NNC)",
	"Arab Socialist Baath Party of Iraq",
	"Joint Meeting Parties (JMP)",
	"National Front for the Revolution in Burundi (FRONABU-Tabara)",
	"Provisional RSPCA",
	"Adivasi People's Army (APA)",
	"Khorasan jihadi group",
	"Baloch Liberation Tigers (BLT)",
	"Kuki National Liberation Front (KNLF)",
	"Yimchunger Liberation Front (YLF)",
	"Muslim United Liberation Tigers of Assam (MULTA)",
	"Zeliangrong United Front",
	"Hekla Reception Committee-Initiative for More Social Eruptions",
	"Hill Tiger Force (HTF)",
	"Movement for Oneness and Jihad in West Africa (MUJAO)",
	"Liberation and Justice Movement (LJM)",
	"Soldiers of the Caliphate",
	"Comando Autónomo Voltaire Argandoña",
	"United Baloch Army (UBA)",
	"Free Syrian Army",
	"Revolutionary Insurgent Armed Forces of Ecuador (FAIRE)",
	"Eritrean Salvation Front (ESF)",
	"Islamic Movement of Kashmir",
	"Individuals Tending Toward Savagery",
	"Zintani Militia",
	"Core Conspirators for the Extension of Chaos (Núcleo de Conspiradores por la Extensión de Kaos)",
	"Adivasi Cobra Militants of Assam (ACMA)",
	"Shabelle Valley militia",
	"Al-Nusrah Front",
	"Janatantrik Terai Madhes Mukti Party- Bhagat Singh (JTMMP)",
	"Azawad National Liberation Movement (MNLA)",
	"Qari Kamran Group",
	"Samyukta Terai Madhes Mukti Party",
	"Sudan People's Liberation Movement - North",
	"Syrian Army deserters",
	"Tehrik-e-Taliban Islami (TTI)",
	"Sudan Liberation Army-Minni Minawi (SLA-MM)",
	"Urban Guerrilla War",
	"Revolutionary Front",
	"Coordination Committee (CORCOM)",
	"Bhittani tribe",
	"Zetas",
	"Jharkhand Janmukti Parishad (JJP)",
	"Sindhudesh Revolutionary Army (SRA)",
	"National Committee for the Restoration of Democracy and State (CNRDR)",
	"People's Revolutionary Party of Kangleipak-Progressive (PREPAK-P)",
	"Communist Party of Nepal (People's War Group)",
	"Supporters of Ali Abdullah Saleh",
	"Civilian Defense",
	"Al-Naqshabandiya Army",
	"Ansar al-Dine (Mali)",
	"Sudanese Rebels",
	"South Sudan Democratic Army",
	"Pro Hartal Activists",
	"Mai Mai Simba Militia",
	"Force Etudiante Critique",
	"Shiv Sena",
	"Friends of Loukanikos",
	"Telangana Separatists",
	"Islami Jamiat-e-Talaba (IJT)",
	"Mullah Dadullah Front",
	"Brigades of Imprisoned Sheikh Omar Abdel-Rahman",
	"Nepalbad Party",
	"Knights Templar (Caballeros Templarios)",
	"Republican Action Against Drugs (RAAD)",
	"Gbagbo Loyalists",
	"People's Amn Committee",
	"Mujahedeen Shura Council in the Environs of Jerusalem",
	"Sovereign Citizen",
	"International Revolutionary Front",
	"Izberbash Gang",
	"Partido Marxista-Leninista ng Pilipinas (PMLP)",
	"Sindh Liberation Front",
	"Tolib Ayombekov loyalists",
	"Brigade of al-Mukhtar al-Thaqafi",
	"People's Protection Units (YPG)",
	"Jaljala Army",
	"Bangsamoro Islamic Freedom Movement (BIFM)",
	"Kilafah Islamic Movement",
	"Proletariat Self-defense Groups",
	"Dissident Republican Guard",
	"Ansar Bayt al-Maqdis (Ansar Jerusalem)",
	"Pro-LGBT Rights extremists",
	"Jaish-e-Islam",
	"David Yau Yau Militia",
	"Ansar al-Sharia (Libya)",
	"Ansar al-Sharia (Tunisia)",
	"Resistencia Galega",
	"Jaish Usama",
	"Yakariya Bango Insurgent Group",
	"Tajamo Ansar al-Islam",
	"Mombasa Republican Council (MRC)",
	"National Freedom Party",
	"Zomi Revolutionary Army (ZRA)",
	"Mujahidin Indonesia Timur (MIT)",
	"Bengali Sangram Mukti Bahini",
	"Harakat Ansar Iran (HAI)",
	"The New Irish Republican Army",
	"Sudurpaschim Janata Dal",
	"Rohingya Solidarity Organization",
	"M23",
	"Diraa al-Shahbaa Rebel Brigade",
	"Tawheedul Islam",
	"Popular Front for Recovery (FPR)",
	"Militant Minority (Greece)",
	"Punjabi Taliban",
	"Biswabhumi Sena Bishal Nepal",
	"Mapuche extremists",
	"Mujahideen Ansar",
	"Ansarullah Bangla Team",
	"Group of Popular Fighters",
	"Al-Mua'qi'oon Biddam Brigade (Those who Sign with Blood)",
	"Golden Dawn",
	"Wild Freedom",
	"National Liberation Front of Provence (FLNP)",
	"Bedouin Movement (Sudan)",
	"Anti-Police extremists",
	"Mateo Morral Insurrectionist Commandos",
	"Mukhtar Army",
	"Muslim Renewal",
	"February 14th Movement",
	"Liwa al-Islam",
	"Communist Party of Nepal-Maoist (Baidya)",
	"Yarmouk Martyrs Brigade",
	"Al-Herak Al-Tihami Movement",
	"Overall Deniers of Joining the Existing",
	"Islamic State of Iraq and the Levant (ISIL)",
	"Epanastatiki Anatropi (Revolutionary Overthrow)",
	"Terai Communist Party",
	"National Democratic Front-Bicol (NDF-Bicol)",
	"Hassan Mabkhut Group",
	"Anti-Clerical Pro-Sex Toys Group",
	"Maoist Communist Party of Manipur",
	"Colonel Albert Kahasha Militia",
	"Sayfullakh",
	"Balakhani Group",
	"Zehri Youth Force (ZYF)",
	"Pattali Makkal Katchi (PMK)",
	"Jumuiya ya Taasisi za Kiislam (Community of Muslim Organizations)",
	"Abdul Qader Husseini Battalions of the Free Palestine movement",
	"Anti-Gentrification Front",
	"Anti-Gun Control extremists",
	"Pattani United Liberation Organization-MKP (PULO-MKP)",
	"Armed Group for the Defence of the People",
	"Al-Ashtar Brigades",
	"Sudan Revolutionary Front (SRF)",
	"English Defense League (EDL)",
	"Los Rastrojos (Colombia)",
	"Borderless Solidarity Cell (BSC)",
	"Ahrar al-Sham",
	"United Achik Liberation Army (UALA)",
	"313 Brigade (Syria)",
	"Liwa al-Sham",
	"Civilian Joint Task Force (JTF)",
	"Detonators of Social Uprisings",
	"Unsubordinated Desires",
	"Pahadi Cheetah",
	"Okba Ibn Nafaa Brigade",
	"Liwa al-Haqq",
	"Students For Insurrection",
	"Zuwar al-Imam Rida",
	"Four Martyrs Brigade",
	"The Mujahadeen Room in Latakia Countryside",
	"Aisha Umm-al Mouemeneen (Brigades of Aisha)",
	"Popular Resistance Brigade",
	"Amazigh Islamic Front",
	"National Liberation Force of Bengalis (Bangali Janamukti Bahini)",
	"Sanatan Sanstha",
	"Veterans United for Non-Religious Memorials",
	"Angry Foxes Cell",
	"Al-Furqan Brigades",
	"Makhachkala Gang",
	"Defenders of the Nation's Sovereignty",
	"Mapuche Ancestral Resistance (RAM)",
	"Ombatse Cult",
	"Vanguards of the Caliphate",
	"Caspian Group",
	"Bru National Liberation Front (BNLF)",
	"Powers of the Revolutionary Arc",
	"Moro Ghuraba",
	"Liwa al-Tawhid",
	"Aba Cheali Group",
	"Al-Qaida in the Indian Subcontinent",
	"Libya Revolutionaries Operations Room (LROR)",
	"Ayesha bint al-Sadiq Brigade",
	"Jaish al-Adl",
	"Militant People's Revolutionary Forces",
	"Ansaru ash-Sharia (Russia)",
	"Achik National Volunteer Council-B (ANVC-B)",
	"Manipur Naga People's Army (MNPA)",
	"Seleka",
	"Jaish al-Mukhtar",
	"Anti-Balaka Militia",
	"Baloch National Liberation Front",
	"Unification Army Sons Brigade",
	"National Democratic Alliance Army (NDAA-ESSA)",
	"Tehrik-e-Nifaz-e-Aman Balochistan-Jhalawan Brigade (TNAB-Jhalawan Brigade)",
	"Zwai Tribe",
	"Libya Shield Force",
	"Jund al-Islam",
	"Abu Jaafar al-Mansur Brigades",
	"Hadramawt Tribes Alliance",
	"Tabu Tribe",
	"Achik Tiger Force",
	"Abu Bakr Unis Jabr Brigade",
	"Abu Tira (Central Reserve Forces)",
	"Tehama Movement",
	"Sudan People's Liberation Movement in Opposition (SPLM-IO)",
	"National Liberation Council of Taniland",
	"Ajnad Misr",
	"Achik National Liberation Army (ANLA)",
	"Al-Qaqa Brigade",
	"Islamic Front (Syria)",
	"Tarok Militia",
	"Anti-Yanukovych extremists",
	"Baloch Waja Liberation Army (BWLA)",
	"Mai Mai Bakata Katanga Militia",
	"Tehrik-e-Khilafat",
	"Right Sector",
	"Mustafa al-Hujairi Group",
	"Jaish as-Saiyouf (Army of Swords)",
	"The Association for Islamic Mobilisation and Propagation (UAMSHO)",
	"Pro-Russia Militia",
	"Biafra Zionist Movement (BZM)",
	"Russian Unity",
	"Cyrenaica Self-Defense Force",
	"United Tribal Liberation Army (UTLA)",
	"Al-Islah Party",
	"Raskamboni Movement",
	"Liwa Ahrar al-Sunna",
	"Tiv Militia",
	"Al-Mus'abi Tribe",
	"Achik Songna An'pachakgipa Kotok (ASAK)",
	"National Santhali Liberation Army (NSLA)",
	"Military Council of the Tribal Revolutionaries (MCTR)",
	"Orakzai Freedom Movement",
	"Donetsk People's Republic",
	"Ses'khona Peoples' Rights Movement",
	"Sudanese People's Front",
	"Jaish Tahkim al-Din",
	"Luhansk People's Republic",
	"Alliance of Patriots for a Free and Sovereign Congo (APCLS)",
	"Democratic Front of the Central African People (FDPC)",
	"Tanzeem al-Islami al-Furqan",
	"Haftar Militia",
	"Student Network of Mandalay",
	"Jaish al-Islam (Libya)",
	"Baloch Republican Guards (BRG)",
	"Rafallah al-Sahati Brigade",
	"Jharkhand Bachao Aandolan",
	"Organization for Revolutionary Self Defense",
	"United Karbi Liberation Army (UKLA)",
	"Bodu Bala Sena",
	"Dima Hasao National Army",
	"Southern Front",
	"Mitiga Militia",
	"Saraya al-Mukhtar",
	"Bangalee Somo Odhikar Andolon",
	"Libyan Militia",
	"Sinai Province of the Islamic State",
	"Barq al-Nasser Brigade",
	"Hamza Combat Group against Atheism and Heresy",
	"Armed Forces for National Salvation - Army of the People (FASN-EP)",
	"National Socialist Council of Nagaland-Khole-Kitovi (NSCN-KK)",
	"Islamic Youth Shura Council",
	"February 17 Martyrs Brigade",
	"Achik National Cooperative Army (ANCA)",
	"Misrata Brigades",
	"Al-Madani Brigade",
	"Rabha National Security Force",
	"Greater Damascus Operations Room",
	"Mabanese Defence Forces",
	"Knights of Janzour",
	"Orfei Harsha'am",
	"Abu Obaida bin Jarrah Brigade",
	"Jaish-e-Khorasan (JeK)",
	"Unified Communist Party of Nepal (Maoist)",
	"German Resistance Movement",
	"Halqa-e-Mehsud",
	"Jamaat-ul-Ahrar",
	"Random Anarchists",
	"Proletarian Solidarity",
	"Al-Jihad (Pakistan)",
	"Shura Council of Benghazi Revolutionaries",
	"Jund al-Khilafa",
	"Nduma Defense of Congo (NDC)",
	"Jharkhand Prastuti Committee (JPC)",
	"Manipur Nationalist Revolutionary Party (MNRP)",
	"The Irish Volunteers",
	"Economic Freedom Fighters",
	"Cossack Separatists",
	"Army of the Tribes",
	"Al-Zintan Revolutionaries' Military Council",
	"Yazidi extremists",
	"Donskoy Army",
	"Al-Sawaiq Brigade",
	"Mawlawi and Mansour Group",
	"Zawiya Martyrs Brigade",
	"Barqa Province of the Islamic State",
	"Anarchist Anti-Capitalist Action Group",
	"Kharkiv Partisans",
	"Youth of the Land of Egypt",
	"Tripoli Province of the Islamic State",
	"Proletarian Assault Group",
	"Hafiz Gul Bahadur Group",
	"Free Balochistan Army (FBA)",
	"Supporters of the Islamic State in the Land of the Two Holy Mosques",
	"United Aryan Empire",
	"Al-Faruq Militia",
	"Yekbun",
	"Lehava",
	"Khorasan Chapter of the Islamic State",
	"Ta'ang National Liberation Army (TNLA)",
	"Anarchist Attack Consortium",
	"Jund Ansar Allah",
	"Free Network South (Freies Netz Sued)",
	"Arab Movement of Azawad (MAA)",
	"Shura Council of Mujahideen in Derna",
	"Shamiya Front",
	"Fezzan Province of the Islamic State",
	"Popular Resistance Movement (Egypt)",
	"Revenge Movement",
	"Marwan Al-Qawasimi and Amir Abu-Ayshah Group",
	"Anarchist Commando Nestor Makhno Group",
	"Revolutionary Movement for National Salvation (REMNASA)",
	"Self-Defense Group of Imghad Tuaregs and Allies (GATIA)",
	"Revolutionary Punishment Movement",
	"Barak Valley Tiger Force (BVTF)",
	"Bru Democratic Front of Mizoram (BDFM)",
	"Murle Tribe",
	"Algeria Province of the Islamic State",
	"Maoist Communist Party (MKP)",
	"Al-Saadawi Militia",
	"Al-Sham Legion",
	"Ansar al-Din Front",
	"Ahle Sunnat Wal Jamaat (ASWJ-Pakistan)",
	"Gathering to Aid the Oppressed",
	"Kouweikhat Group",
	"A'chik Matgrik Elite Force (AMEF)",
	"Patriotic Europeans against the Islamization of the West (PEGIDA)",
	"Supporters of Abd Rabbuh Mansur Hadi",
	"Hindu Illaignar Sena",
	"Jund al-Khilafah (Tunisia)",
	"Sanaa Province of the Islamic State",
	"Lahij Province of the Islamic State",
	"Jaysh al-Islam (Syria)",
	"West Indonesia Mujahideen",
	"Nihilistic Patrol and Neighborhood Arsonists",
	"People's Defense Unit (Turkey)",
	"Jamaah Ansharut Daulah",
	"Jharkhand Sangharsh Jan Mukti Morcha",
	"Urhobo Gbagbako",
	"Popular Resistance Committees (Yemen)",
	"Shabwah Province of the Islamic State",
	"Ukrainian Insurgent Army",
	"Dravidar Viduthalai Kazhagam (DVK)",
	"Communist Party of Nepal - Maoist (CPN-Maoist-Chand)",
	"Macina Liberation Front (FLM)",
	"Coordination of Azawad Movements (CMA)",
	"Nyatura Militia",
	"Hadramawt Province of the Islamic State",
	"Abdul Ghani Kikli Militia",
	"United National Liberation Front of WESEA (UNLFW)",
	"Supporters of the Islamic State in Jerusalem",
	"Najd Province of the Islamic State",
	"Lashkar-e-Islam (India)",
	"Lama Group",
	"Medfaiyah Wal-Sewarigh Brigade",
	"Revolutionary Cells Network (SRN)",
	"The Revolt",
	"Odessa Underground",
	"Ansar al-Sharia Operations Room (Syria)",
	"Jaish al-Fatah (Syria)",
	"Islamic State in Egypt",
	"National Liberation Front (Ecuador)",
	"The Third Way (Der III. Weg)",
	"Group of the Fatima Church Attack",
	"Aleppo Fatah Operations Room",
	"Puratchi Puligal",
	"Freital Group",
	"Saraya Waad Allah",
	"Hijaz Province of the Islamic State",
	"Free and Democratic Society of Eastern Kurdistan (KODAR)",
	"Adan-Abyan Province of the Islamic State",
	"Arakan Army (AA)",
	"Magahat Militia",
	"Sheikh Omar Hadid Brigade",
	"Baloch Young Tigers (BYT)",
	"Death Squad (Iraq)",
	"Al-Haydariyah Battalion",
	"Hindu Morcha Nepal",
	"Rubicon (Rouvikonas)",
	"Bangladesh Tiger Force",
	"Islamic State in Bangladesh",
	"United Garo Security Force (UGSF)",
	"Shura Council of Ajdabiya Revolutionaries",
	"Arakan Liberation Party (ALP)",
	"Sunni Resistance Committees in Lebanon (SRCL)",
	"Awdal Regional Administration Army (ARAA)",
	"Al-Furqan (Tunisia)",
	"Tepi Youth",
	"Jai Shri Ram Hindu Bhai Group",
	"Free Libya Martyrs Brigade",
	"Aceh Singkil Islamic Care Youth Students Association (PPI)",
	"Bahrain Province of the Islamic State",
	"Maute Group",
	"Shan State Army - North (SSA-N)",
	"Majlis-e-Askari",
	"Pagan Sect of the Mountain",
	"Anti-Sikh extremists",
	"Baba Ladla Gang",
	"Abu Salim Martyr's Brigade",
	"United Democratic Madhesi Front (UDMF)",
	"United Gorkha People's Organization (UGPO)",
	"Ansar Al-Khilafa (Philippines)",
	"Revolutionaries Army (Jaysh al-Thowwar)",
	"South Sudan Armed Forces (SSAF)",
	"Berom Militants",
	"Ansar al-Imam Musa al-Sadr",
	"Islamic Movement (Nigeria)",
	"Patriotic Ginbot 7 Movement for Unity and Democracy (PGMUD)",
	"Kata'ib al-Imam Ali",
	"Anarchist Cell Acca (C.A.A.)",
	"Raia Mutomboki Militia",
	"Ramiro Ledesma Social Centre",
	"Lekagak Tenggamati",
	"Caucasus Province of the Islamic State",
	"Citizens for Constitutional Freedom",
	"Harakat al-Nujaba",
	"Achik Matgrik Army (AMA)",
	"Democratic Youth Federation of India (DYFI)",
	"United Reformation Protest of India",
	"Moran Tiger Force (MTF)",
	"Janatantrik Tiger Janashakti Party",
	"Union of Patriots for the Defense of Innocents (UPDI)",
	"Antagonistic Nuclei of the New Urban Guerrilla",
	"Niger Delta Avengers (NDA)",
	"Youth Movement for the Total Liberation of Azawad",
	"Kuki Independent Organization (KIO/KIA)",
	"Wampis extremists",
	"Weichan Auka Mapu",
	"Tawheed Militia",
	"Republican Forces of Burundi (FOREBU)",
	"Civil Protection Units (YPS)",
	"Mai Mai Karakara Militia",
	"Court Reform extremists",
	"Tripoli Revolutionaries Battalion",
	"Liberation of Achik Elite Force (LAEF)",
	"Base Movement",
	"Jaish al-Umar (JaU)",
	"Jundul Khilafah (Philippines)",
	"Syrian Turkmen Brigades",
	"Gunib Group",
	"Tripura Group",
	"Jund al-Aqsa",
	"South Group (Russia)",
	"Jabha East Africa",
	"Tribal Revolutionary Army (TRA)",
	"Kani Brigade",
	"Omar Mukhtar Militia",
	"Nur-al-Din al-Zinki Movement",
	"Kizilyurtovskiy Group",
	"National Socialist Council of Nagaland-Unification (NSCN-U)",
	"Revolutionary Communist Centre (RCC)",
	"Rohingya extremists",
	"Abu Amarah Battalion",
	"Red Egbesu Water Lions",
	"Tiwa Liberation Army",
	"South East Antrim Brigade",
	"Niger Delta extremists",
	"Concerned Militant Leaders (CML)",
	"Kazakhstan Liberation Army",
	"Ghniwah Militia",
	"Suqour al-Ahvaz",
	"Ansar al-Furqan",
	"Jaish al-Sunnah",
	"Islamic Movement for the Liberation of Raja",
	"Benghazi Defense Brigades (BDB)",
	"Anti-Trump extremists",
	"National Revolutionary Front of Manipur (NRFM)",
	"Shamil Group",
	"Les Casseurs",
	"Red Scorpion",
	"Ahfad al-Sahaba-Aknaf Bayt al-Maqdis",
	"Black Hebrew Israelites",
	"Peace at Home Council",
	"Mai Mai Manu Militia",
	"Hasam Movement",
	"Sasna Tsrer",
	"Mai Mai Mazembe Militia",
	"Al-Nasir Army (Syria)",
	"Fetullah Terrorist Organization",
	"Jaish al-Mujahideen (Syria)",
	"Ahmad Luebaesa Group",
	"League of Damascus for Special Tasks",
	"Opponents of disabled people",
	"Movimiento Revolucionario Alfarista (MRA) - Comuneros Montanas del Ecuador",
	"New Indigenous People's Army",
	"Peoples' United Revolutionary Movement (HBDH)",
	"Niger Delta Justice Defense Group (NDJDG)",
	"Kuki Unification Frontal Organization (KUFO)",
	"Vetevendosje",
	"Dashmesh Regiment",
	"All Assam Revolutionary Army (AARA)",
	"Unrepentant Anarchists",
	"Niger Delta Greenland Justice Mandate (NDGJM)",
	"Syrian Democratic Forces (SDF)",
	"Nuer White Army",
	"Revolution's Brigade",
	"Rugovasit Group",
	"Lone Wolves of Radical, Autonomous, Militant National Socialism",
	"Chama Pueblo en Rebelion",
	"Islamic State in the Greater Sahara (ISGS)",
	"The 78 Unemployed",
	"Authenticity and Development Front",
	"Thanthai Periyar Dravidar Kazhagam (TPDK)",
	"Kamwina Nsapu Militia",
	"Return, Reclamation, Rehabilitation (3R)",
	"Martyr al-Nimr Battalion",
	"Tehrik-e-Hurriyat (TeH)",
	"Al-Yakin Mujahidin",
	"Agwelek Forces",
	"Abbala extremists",
	"Alde Hemendik Movement",
	"No Borders Group",
	"Popular Mobilization Forces (Iraq)",
	"Union for Peace in Central Africa (UPC)",
	"Ajnad al-Sham",
	"Bahoz",
	"Nordic Resistance Movement",
	"Al Bayda Province of the Islamic State",
	"Popular Front for the Renaissance of the Central African Republic (FPRC)",
	"Shuar extremists",
	"Ma'sadat al-Mujahideen",
	"Khaled Ibn al-Walid Army",
	"Anti-Park extremists",
	"Jharkhand Kranti Raksha Dal (Utari Chotanagpur)",
	"Conspiracy theory extremists",
	"Ansar al-Islam (Burkina Faso)",
	"The Independent Military Wing of the Syrian Revolution Abroad",
	"Haji Fateh",
	"Al-Fateh Al-Jadid",
	"Arab-Israeli extremists",
	"Groups of Martyr Baha Eleyan",
	"Al-Zawiya Syndicates Council",
	"Misanthropic Division",
	"Hay Andalus Defense Operations Room",
	"Fajr al-Umma Brigade",
	"People's Revolutionary Movement (MRP)",
	"Anti-LGBT extremists",
	"Abu Abbas Brigade",
	"Twa Militia",
	"Hay'at Tahrir al-Sham",
	"Revolutionary Armed Forces of Colombia (FARC) dissidents",
	"Thadou People's Liberation Army (TPLA)",
	"Quit Kashmir Movement (QKM)",
	"Cells of Direct Attack - Living Waste Group",
	"Conspiracy of Vengeful Arsonists",
	"United Achik National Front (UANF)",
	"Benishangul Gumuz People's Liberation Movement",
	"Jamaat Nusrat al-Islam wal Muslimin (JNIM)",
	"Council for Justice in Azawad",
	"Nikhil Bharat Bangali Udbastu Samannay Samiti (NBBUSS)",
	"Naga extremists",
	"Anti-Arab extremists",
	"Rajput extremists",
	"Fire and Flame for the Police (FFdP)",
	"Misurata Rebels",
	"Zagros Eagles",
	"Wild Individualities",
	"Good Vision Party (India)",
	"Nazhdak Radical Public Organization",
	"Imam Shamil Battalion",
	"Bilal Badr Group",
	"Provisional National Government of Vietnam",
	"Nalut Group",
	"Bab Tajura Brigade",
	"Fight Xenophobia",
	"South Sudanese extremists",
	"Patria Grande Ejercito del Pueblo",
	"National Council for Revival and Democracy (CNRD)",
	"Jordanian extremists",
	"National Socialist Council of Nagaland-Reformation (NSCN-R)",
	"Niger Delta Forest Army (NDFA)",
	"Irrintzi",
	"Birsa Commando Force (BCF)",
	"Mai Mai Charles Militia",
	"Union of Peoples and Organizations of the State of Guerrero (UPOEG)",
	"Gorkha Janmukti Morcha (GJM)",
	"Anti-Republican extremists",
	"Shutdown G20: Take Hamburg offline!",
	"National Movement of Revolutionaries (MNR)",
	"Ansar al-Sharia (Pakistan)",
	"Liberal Democratic Council for Missing Land",
	"Equilibrio Nacional",
	"Hizballah-Iraq",
	"United Party for National Development (UPND)",
	"Justicieros de la Frontera",
	"Nawasi Brigade",
	"Informal Feminist Commando for Anti-authoritarian Action",
	"Residents and regulars of Exarchia",
	"Arakan Rohingya Salvation Army (ARSA)",
	"White Rabbit Three Percent Illinois Patriot Freedom Fighters Militia",
	"Pragatishil Tarai Mukti Morcha",
	"Gorkha Liberation Army (GLA)",
	"Apella",
	"Mai Mai Yakutumba Militia",
	"Ambazonia Defense Forces (ADF)",
	"Indigenous People of Biafra (IPOB)",
	"Christian State-Holy Rus",
	"The Defense Command of the French People and the Motherland (CDPPF)",
	"Ahneish Militia",
	"Anarchist Revolt Against Exiled Gendarmes",
	"Indigenous People's Front of Tripura (IPFT)",
	"Torpedy Group",
	"Liberation Front for English-speaking Cameroon",
	"The Nocturnals",
	"Sinhale Jathika Balamuluwa",
	"Anas al-Dabbashi Brigade",
	"Ansar al-Sunna (Mozambique)",
	"Mai Mai Malaika Militia",
	"Pro-Government extremists",
	"Anarchist Collective of Kallithea-Moschato",
	"Ansar al-Islam (Egypt)",
	"Feminist extremists",
	"Movement for the Restoration of the Independence of Southern Cameroons",
	"Arm na Poblachta' (Army of the Republic)",
	"Crypteia",
	"Hafeez Brohi Group",
	"Bachama extremists",
	"Bedouin Israeli extremists",
	"Ansar Ghazwat-ul-Hind",
	"The Owners of the White Flags",
	"High Council for the Unity of Azawad (HCUA)",
	"Fatoni Warriors",
	"Minorities of Metropolitan Attacks",
	"Baloch Republican Party",
	"Room of the Neighbourhoods Youths",
	"Popular Front of India (PFI)",
	"Free Alawite Movement",
	"Bashir al-Baqarah militia",
	"Jabal al-Islam Brigade",
	"Southern Resistance Forces (SRF)",
	"Women's Protection Units (YPJ)",
	"Ambazonia Tigers",
	"Falanga",
	"Sawaid Al-Khayr",
	"Kata'ib Sayyid al-Shuhada",
	"Pumba and Tata Cartel",
	"Armed Revolutionary Forces",
	"Irigwe Militia",
	"Anti-Fascist Activists",
	"Male supremacists",
	"Action Cell Haukur Hilmarsson",
	"Hizb-ul-Ahrar (HuA)",
	"Dravidar Kazhagam",
	"Vulkangruppe NetzHerrschaft zerreissen",
	"Bastards & Blasphems",
	"S14",
	"Siriri",
	"Sons of Zouari",
	"Tehreek-e-Labbaik Pakistan (TLP)",
	"South Sudan United Front (SSUF)",
	"Resistance to the Rule of Law in Burundi (Red-Tabara)",
	"Negara Islam Indonesia (NII)",
	"Saraya al-Jihad Fi Bilad al-Sham",
	"Ambazonia Restoration Forces (ARF)",
	"Derna Protection Force (DPF)",
	"Mullah Nazir Group",
	"Natsionalnyi Druzhiny",
	"Militarized Communist Party of Peru (MPCP)",
	"Trieu Dai Viet",
	"Sober and Evil Youth",
	"Dogon extremists",
	"Pathalgadi Movement",
	"Afrin Falcons",
	"Nyandan militia",
	"Catholic extremists",
	"Social Patriot Movement",
	"Revenge of the Martyrs (Tha'ir al-Shuhada)",
	"Juwa Party",
	"National Movement of Soldiers in Shirts",
	"Dozo militia",
	"Military Command Council for the Salvation of the Republic (CCSMR)",
	"Cellula Haris Hatzimihelakis/Internazionale nera",
	"Mai Mai Hapa Na Pale",
	"Peaceful Resistance Group of Mataquescuintla",
	"Democratic Progressive Party (Malawi)",
	"National Liberation Front (NLF-Syria)",
	"Omar Abdel Rahman Brigade",
	"Ansar al-Tawhid",
	"SERB Group (Russian Liberation Movement)",
	"Movement of the youth in Tripoli",
	"Revolution Chemnitz",
	"East Asia Division of the Islamic State",
	"Sumud Brigade",
	"Arab Liberation of Al-Ahvaz",
	"Movement for the Salvation of Azawad (MSA)",
	"Savane Pistache gang",
	"Tariq Bin Ziyad militia",
	"Pro-Trump extremists",
	"Chavistas",
	"Bassa Kwomu extremists",
	"Sundar Shanta Nepal",
	"Jeay Sindh Muttahida Mahaz (JSMM)",
	"Hurras al-Din",
	"Lendu militia",
	"Incite the Believers",
	"All (Baksa District) B.F.D.N Sangodan",
	"Jalisco New Generation Cartel (CJNG)",
	"Anger of Fezzan movement",
	"Sham Liberation Army",
	"Wrath of Olives",
	"South Sudan National Movement for Change (SSNMC-Unvuas)",
	"Gaitanista Self-Defense Forces of Colombia (AGC)",
	"Iconoclastic Sect"
]

export default {
	rawData,
	attackTypeMap,
	targetTypeMap,
	weaponTypeMap,
	gnameMap,
	install: function(Vue) {
		Vue.prototype.loadData = (lineChartDataCallback,
			wordCloudDataCallback,
			raceChartDataCallback,
			choroplethDataCallback,
			donutChartCallback
			) => loadData(
			lineChartDataCallback,
			wordCloudDataCallback,
			raceChartDataCallback,
			choroplethDataCallback,
			donutChartCallback)
		Vue.prototype.getLineChartData = () => getLineChartData()
		Vue.prototype.getWordCloudData = () => getWordCloudData()
		Vue.prototype.getRaceChartData = () => getRaceChartData()
		Vue.prototype.getChoroplethData = () => getChoroplethData()
		Vue.prototype.getDonutChartData = () => getDonutChartData()
	}

}
