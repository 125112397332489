<template>
	<div id="title">
	</div>
</template>

<script>
export default {
	name: 'Title',
	mounted() {
	}
}
</script>

<style scoped>
#title {
	background: url(../assets/img/header.jpg) center;
	width: 100%;
	height: 37.5rem;
}
</style>
